import React from 'react';
import Svg, { Path } from 'react-native-svg';
export const VisaSvg = ({ height = 40, width = 40 }) => {
    return (React.createElement(Svg, { width: width, height: height, viewBox: `0 0 ${width} ${height}`, fill: "none" },
        React.createElement(Path, { d: "M32.7058 8C34.5252 8 36 9.47484 36 11.2942V28.7058C36 30.5252 34.5252 32 32.7058 32H7.29415C5.47484 32 4 30.5252 4 28.7059V11.2941C4 9.47484 5.47484 8 7.29415 8H32.7058ZM7.29415 6C4.37027 6 2 8.37027 2 11.2941V28.7059C2 31.6297 4.37027 34 7.29415 34H32.7058C35.6297 34 38 31.6297 38 28.7058V11.2942C38 8.37027 35.6297 6 32.7058 6H7.29415Z", fill: "#DAD8D8" }),
        React.createElement(Path, { d: "M15.7978 23.9754H17.8377L19.1129 16.14H17.0727L15.7978 23.9754Z", fill: "#1434CB" }),
        React.createElement(Path, { d: "M12.1369 21.5292L14.0817 16.14H16.2771L13.0316 23.9756H10.9061L9.13385 17.1683C7.98319 16.4772 7 16.2727 7 16.2727L7.03773 16.1398H10.2529C11.1375 16.1398 11.2205 16.8453 11.2205 16.8453L11.9069 20.3689L12.1369 21.5292Z", fill: "#1434CB" }),
        React.createElement(Path, { d: "M32 23.9754L30.3669 16.1398H28.7271C27.9698 16.1398 27.7854 16.7237 27.7854 16.7237L24.7429 23.9754H26.8694L27.2947 22.8115H29.888L30.1269 23.9754H32ZM28.954 18.2715L29.557 21.2037H27.8822L28.954 18.2715Z", fill: "#1434CB" }),
        React.createElement(Path, { d: "M25.1936 16.3416L24.9024 18.0242C24.0295 17.5432 22.0428 17.4294 22.0426 18.3154C22.0426 18.6673 22.4921 18.9094 23.0339 19.2011C23.8555 19.6435 24.8894 20.2003 24.8894 21.428C24.8894 23.3898 22.7893 24.1105 21.3972 24.1105C20.0055 24.1105 19.0947 23.6681 19.0947 23.6681L19.3979 21.9088C20.2332 22.5793 22.764 22.7698 22.764 21.7065C22.764 21.2597 22.2658 20.9963 21.6881 20.6907C20.8909 20.2692 19.9425 19.7675 19.9425 18.5939C19.9425 16.4424 22.3465 16 23.3588 16C24.2952 16 25.1936 16.3416 25.1936 16.3416Z", fill: "#1434CB" })));
};
