import { COLOR, Icon, View } from '@lookiero/aurora';
import React, { useContext } from 'react';
import { PaymentInstrumentLogo, findLogo } from '../PaymentInstrumentLogo';
import { PaymentInstrumentAccordionContext } from '../PaymentInstrumentSelect/accordions/PaymentInstrumentAccordionContext';
import { useController } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { style } from './PaymentAccordion.styles';
export const PaymentAccordion = ({ children, metadata, testID }) => {
    const { editable } = useContext(PaymentInstrumentAccordionContext);
    const hasError = useController((s) => s.hasError);
    const color = hasError ? COLOR.PRIMARY : COLOR.CONTENT;
    const iconName = editable ? 'pencil' : 'arrow_right';
    const { brand, networks, preferredNetwork } = metadata;
    return (React.createElement(View, { borderColor: color, testID: testID, style: style.accordion, wide: true },
        React.createElement(View, { style: style.logoContainer },
            React.createElement(PaymentInstrumentLogo, { logoName: findLogo(brand, networks, preferredNetwork) })),
        children,
        React.createElement(Icon, { name: iconName, color: COLOR.ICON_LIGHT })));
};
