import { snakeCase } from '../../..//utils/snakeCase';
export const parsePaymentStatus = (status) => {
    return snakeCase(status);
};
export const generateIdealPaymentConfirmError = (error) => {
    return {
        code: 400,
        message: error.message,
        payload: {
            code: 'standard_issue',
        },
    };
};
