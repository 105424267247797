var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as pck from '../../package.json';
import { Engine } from '../domain/models';
import assertUnreachable from '../utils/assertUnreachable';
const throwNotImplementedError = () => {
    throw new Error('Payments bridge has not been configured.');
};
const state = {
    bridge: {
        getToken: () => {
            throwNotImplementedError();
            return Promise.resolve(undefined);
        },
        stripeKey: '',
        stripeUkKey: '',
        commonAssetsPath: '',
        paymentsAssetsPath: '',
        useAvoidKeyboard: () => {
            throwNotImplementedError();
            throw new Error();
        },
        emitTrackingEvent: () => {
            throwNotImplementedError();
        },
        appVersion: '',
        graphqlUri: '',
        useFeatureFlags: () => ({}),
        locale: () => {
            throw new Error();
        },
        scrollView: () => {
            throwNotImplementedError();
        },
        hostUrl: '',
    },
};
export const setPaymentsBridge = (newBridge) => {
    state.bridge = newBridge;
};
export const getToken = () => state.bridge.getToken();
export const mustGetToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const token = yield getToken();
    if (!token) {
        throw new Error('An action required the user token and it is not available yet.');
    }
    return token;
});
export const getCommonAssetsPath = () => state.bridge.commonAssetsPath;
export const getPaymentsAssetsPath = () => state.bridge.paymentsAssetsPath;
export const useAvoidKeyboard = () => state.bridge.useAvoidKeyboard();
export const emitTrackingEvent = (event, payload) => state.bridge.emitTrackingEvent(event, payload);
export const getStripeKey = (engine) => {
    if (engine === Engine.STRIPE)
        return state.bridge.stripeKey;
    if (engine === Engine.STRIPE_UK)
        return state.bridge.stripeUkKey;
    assertUnreachable(engine);
    return '';
};
export const getAppVersion = () => state.bridge.appVersion;
export const getGraphqlUri = () => state.bridge.graphqlUri;
export const getPaymentsFrontVersion = () => pck.version;
export const useFeatureFlags = () => state.bridge.useFeatureFlags();
export const getHostLocale = () => state.bridge.locale();
export const getScrollView = () => state.bridge.scrollView;
export const getHostUrl = () => state.bridge.hostUrl;
