import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    container: {
        borderStyle: '$borderStyle',
        borderColor: '$colorBorderInteractive',
        borderWidth: '$space025',
        borderRadius: '$borderRadius3',
    },
    containerError: {
        borderColor: '$colorBorderInputError',
    },
});
