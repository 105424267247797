import { View } from '@lookiero/aurora';
import React from 'react';
import { PaymentMethodsAccordionType, usePaymentMethodsAccordions, } from '../../accordions/hooks/usePaymentMethodsAccordions/usePaymentMethodsAccordions';
import { useController } from '../../PaymentInstrumentSelect.controller';
import { ErrorBlock } from '../ErrorBlock';
import { PayPalDisclaimer } from '../PayPalDisclaimer';
import { style } from './AvailablePaymentMethods.styles';
export const AvailablePaymentMethods = () => {
    const controller = useController((s) => s);
    const paymentMethodsAccordions = usePaymentMethodsAccordions({
        paymentMethodsType: PaymentMethodsAccordionType.ALL,
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(View, { style: [style.container, controller.hasError && style.containerError] }, paymentMethodsAccordions),
        controller.hasError && React.createElement(ErrorBlock, null),
        React.createElement(PayPalDisclaimer, null)));
};
