export var LogoName;
(function (LogoName) {
    LogoName["AMERICAN_EXPRESS"] = "amex";
    LogoName["APPLE_PAY"] = "apple_pay";
    LogoName["CARD"] = "card";
    LogoName["MASTERCARD"] = "mastercard";
    LogoName["PAYPAL"] = "paypal";
    LogoName["VISA"] = "visa";
    LogoName["CARTES_BANCAIRES"] = "cartes_bancaires";
    LogoName["IDEAL"] = "ideal";
})(LogoName || (LogoName = {}));
