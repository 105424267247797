var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEvent } from '@lookiero/event';
import { useEffect } from 'react';
export const usePaymentInstrumentUpdateInternalEvents = ({ onSuccess = () => Promise.resolve() }) => {
    const { subscribe, unsubscribe } = useEvent();
    useEffect(() => {
        const onSuccessBase = (payload) => __awaiter(void 0, void 0, void 0, function* () {
            yield onSuccess(payload);
        });
        subscribe({ event: 'INTERNAL_PAYMENT_INSTRUMENT_UPDATED' }, onSuccessBase);
        return () => {
            unsubscribe({ event: 'INTERNAL_PAYMENT_INSTRUMENT_UPDATED' }, onSuccessBase);
        };
    }, [onSuccess, subscribe, unsubscribe]);
};
