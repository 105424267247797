import create from 'zustand';
export const store = create((set) => ({
    payments: {},
    init: (id, payload) => set((state) => {
        if (state.payments[id])
            return;
        state.payments[id] = Object.assign(Object.assign({}, payload), { success: false, isLoading: true, metadata: undefined });
    }),
    finalize: (id, payload) => set((state) => {
        if (!state.payments[id])
            return;
        const currentPayload = state.payments[id];
        state.payments[id] = Object.assign(Object.assign(Object.assign({}, currentPayload), payload), { isLoading: false });
    }),
    remove: (id) => set((state) => {
        delete state.payments[id];
    }),
}));
