import { ALIGN, COLOR, DISPLAY, IMAGE_RESIZE_MODES, SPACE, Image, Modal, Text, View, useDevice, } from '@lookiero/aurora';
import React from 'react';
import { Platform } from 'react-native';
import { ImageSizes } from '../../domain/models';
import { useAnimatedVisibility } from '../../hooks/useAnimatedVisibility';
import { getCDNImages } from '../../infrastructure/getCDNImages';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { useController } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { Portal } from '../Portal';
import { Spinner } from '../Spinner';
import { TEXT } from './PayPalRedirectionModal.definition';
import { style } from './PayPalRedirectionModal.styles';
export const PayPalRedirectionModal = () => {
    const { translate } = useLocale();
    const { screen } = useDevice();
    const modalHeight = screen.S ? screen.height - 48 : 'auto';
    const { src, srcSet } = getCDNImages('paypal-horizontal', ImageSizes.S);
    const panelVisibility = useController((s) => s.panelVisibility);
    const { isRendered, isVisible } = useAnimatedVisibility(panelVisibility.paypalRedirectionModal.visible);
    if (!isRendered)
        return React.createElement(React.Fragment, null);
    return (React.createElement(Portal, null,
        React.createElement(Modal, { isVisible: isVisible },
            React.createElement(View, { alignItems: ALIGN.CENTER, justifyContent: ALIGN.CENTER, display: DISPLAY.FLEX, style: { height: modalHeight } },
                Platform.OS !== 'android' && React.createElement(Spinner, { isVisible: true, marginBottom: SPACE.SPACE_6 }),
                React.createElement(Text, { align: ALIGN.CENTER, color: COLOR.TEXT_MEDIUM, level: 2, detail: true, marginBottom: SPACE.SPACE_6 }, translate(TEXT.PARAGRAPH)),
                React.createElement(View, { alignItems: ALIGN.CENTER },
                    React.createElement(Image, { autoSize: true, resizeMode: IMAGE_RESIZE_MODES.contain, src: src, srcSet: srcSet, style: style.logo }))))));
};
