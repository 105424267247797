import { ALIGN, Box, Layout, Text, SPACE } from '@lookiero/aurora';
import React, { useContext } from 'react';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { AvailablePaymentMethods } from '../PaymentInstrumentSelect/internals/AvailablePaymentMethods';
import { PaymentInstrumentSelectControllerContext, useController, } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { TEXT } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.definition';
import { PaymentPanel } from '../PaymentPanel';
export const AvailablePaymentMethodsPanel = () => {
    const storeInstance = useContext(PaymentInstrumentSelectControllerContext);
    const controller = useController((s) => s);
    const { translate } = useLocale();
    const handleOnClose = () => controller.setShowAvailablePaymentMethodsPanel(false);
    const { visible, disableAnimation } = controller.panelVisibility.availablePaymentMethodsSelect;
    return (React.createElement(PaymentPanel, { id: "PaymentInstrumentSelectPanel", title: translate(TEXT.PANEL_PAYMENT_METHOD_SELECT_TITLE), isVisible: visible, disableAnimation: disableAnimation, onClose: handleOnClose },
        React.createElement(PaymentInstrumentSelectControllerContext.Provider, { value: storeInstance },
            React.createElement(Layout, { alignItems: ALIGN.START, justifyContent: ALIGN.CENTER, testID: "Layout" },
                React.createElement(Box, { size: { M: '2/3', L: '1/3' }, style: { flexDirection: 'column' } },
                    React.createElement(Text, { heading: true, level: 3, marginBottom: SPACE.SPACE_6 }, translate(TEXT.PANEL_PAYMENT_METHOD_SELECT_BODY)),
                    React.createElement(AvailablePaymentMethods, null))))));
};
