var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { View } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { PaymentMethod, } from '../../domain/models';
import { usePaymentInstrument } from '../../hooks/usePaymentInstrument';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { paymentInstrumentFinalizerStore } from '../../services/PaymentInstrumentFinalizer';
import { emitGlobalError, emitPaymentInstrumentUpdated, internalEmitPaymentInstrumentUpdated, } from '../../utils/eventEmitter';
import { cleanUrl } from '../../utils/url';
import { AvailablePaymentMethodsPanel } from '../AvailablePaymentMethodsPanel';
import { CardUpdaterPanel } from '../CardUpdaterPanel';
import { EngineProvider, useSetupEngine } from '../EngineProvider';
import { LoadingPanel } from '../LoadingPanel';
import { TEXT as IDEAL_TEXT } from '../PaymentInstrumentSelect/accordions/IdealAccordion/ideal.definition';
import { PaymentMethodsPanel } from '../PaymentMethodsPanel';
import { PayPalRedirectionModal } from '../PayPalRedirectionModal';
import { useAvailableIntegrations } from './internals/AvailableIntegrations';
import { AvailablePaymentMethods } from './internals/AvailablePaymentMethods';
import { CurrentPaymentMethod } from './internals/CurrentPaymentMethod';
import { DelayedHandler } from './internals/DelayedHandler';
import { PendingPaymentMethod } from './internals/PendingPaymentMethod/PendingPaymentMethod';
import { SingleUsePaymentMethods } from './internals/SingleUsePaymentMethods';
import { Skeleton } from './internals/Skeleton';
import { useController, useControllerFactory } from './PaymentInstrumentSelect.controller';
import { TEXT } from './PaymentInstrumentSelect.definition';
export const PaymentInstrumentSelect = forwardRef((props, ref) => {
    var _a;
    const availableIntegrations = useAvailableIntegrations(props.section);
    const { locale } = useLocale();
    const engine = (_a = availableIntegrations.integrations[0]) === null || _a === void 0 ? void 0 : _a.engine;
    const engineInstance = useSetupEngine(engine);
    const ControllerProvider = useControllerFactory({
        availableIntegrations,
        hasError: props.hasError || false,
        hidePaymentMethods: props.hidePaymentMethods || [],
        section: props.section,
        beforeRedirectCallback: props.beforeRedirect,
        disclaimers: props.disclaimers,
        userInformation: props.userInformation,
        showSingleUsePaymentMethods: props.showSingleUsePaymentMethods,
    });
    if (!engine || !locale || !engineInstance)
        return React.createElement(Skeleton, null);
    return (React.createElement(EngineProvider, { engine: engine, locale: locale, engineInstance: engineInstance },
        React.createElement(ControllerProvider, null,
            React.createElement(PaymentInstrumentSelectComposer, { ref: ref }))));
});
const PaymentInstrumentSelectComposer = forwardRef((props, ref) => {
    const delayedHandlingRef = useRef();
    useImperativeHandle(ref, () => ({
        runDelayedHandling: (props) => __awaiter(void 0, void 0, void 0, function* () {
            if (delayedHandlingRef.current) {
                yield delayedHandlingRef.current.runDelayedHandling(props);
            }
        }),
    }));
    return (React.createElement(React.Fragment, null,
        React.createElement(AvailablePaymentMethodsPanel, null),
        React.createElement(LoadingPanel, null),
        React.createElement(PaymentMethodsPanel, null),
        React.createElement(CardUpdaterPanel, null),
        React.createElement(PayPalRedirectionModal, null),
        React.createElement(View, null,
            React.createElement(NextPaymentElement, null)),
        React.createElement(DelayedHandler, { ref: delayedHandlingRef }),
        React.createElement(PaymentInstrumentStatusFinalizer, null)));
});
const NextPaymentElement = () => {
    const { availableIntegrations, showSingleUsePaymentMethods } = useController((s) => s);
    const { loading: paymentInstrumentLoading, pendingPaymentInstrument, info } = usePaymentInstrument();
    const [returnedStatusHandlerIsFinalizing] = paymentInstrumentFinalizerStore((s) => [s.finalizing]);
    const isLoading = availableIntegrations.loading || paymentInstrumentLoading || returnedStatusHandlerIsFinalizing;
    if (isLoading)
        return React.createElement(Skeleton, null);
    const pendingPaymentMethodAvailable = isPendingPaymentMethodAvailable(paymentInstrumentLoading, pendingPaymentInstrument, availableIntegrations.integrations);
    if (showSingleUsePaymentMethods && pendingPaymentInstrument && pendingPaymentMethodAvailable) {
        return React.createElement(PendingPaymentMethod, { paymentInstrument: pendingPaymentInstrument });
    }
    if (info != null) {
        return (React.createElement(React.Fragment, null,
            React.createElement(CurrentPaymentMethod, { paymentInstrument: info }),
            showSingleUsePaymentMethods && React.createElement(SingleUsePaymentMethods, null)));
    }
    return React.createElement(AvailablePaymentMethods, null);
};
const isPendingPaymentMethodAvailable = (paymentInstrumentLoading, pendingPaymentInstrument, availableIntegrations) => {
    var _a;
    return Boolean(!paymentInstrumentLoading &&
        pendingPaymentInstrument &&
        ((_a = availableIntegrations[0]) === null || _a === void 0 ? void 0 : _a.singleUsePaymentMethods.includes(pendingPaymentInstrument.payment_method)));
};
const PaymentInstrumentStatusFinalizer = () => {
    const paymentInstrument = usePaymentInstrument();
    const returnedStatusHandlerState = paymentInstrumentFinalizerStore();
    const controller = useController((s) => s);
    const { publish } = useEvent();
    const { translate } = useLocale();
    useEffect(() => {
        returnedStatusHandlerState.finalizing && controller.hideAllPanels();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnedStatusHandlerState.finalizing]);
    useEffect(() => {
        returnedStatusHandlerState.consumeResult((r) => __awaiter(void 0, void 0, void 0, function* () {
            yield paymentInstrument.refresh();
            if (r.success) {
                controller.hideAllPanels();
                if (r.paymentMethod === PaymentMethod.PAYPAL) {
                    yield paymentInstrument.updatePendingPaymentInstrument(r.pendingPaymentInstrument ? r.pendingPaymentInstrument : null);
                    const notificationText = r.pendingPaymentInstrument
                        ? IDEAL_TEXT.NOTIFICATION_SUCCESS_IDEAL_SELECTED_AND_OTHER_TOKENIZED
                        : TEXT.NOTIFICATION_SUCCESS;
                    setTimeout(() => {
                        emitPaymentInstrumentUpdated(publish, PaymentInstrumentStatusFinalizer.name, Object.assign(Object.assign({}, notificationText), { translation: translate(notificationText) }), PaymentMethod.PAYPAL);
                        internalEmitPaymentInstrumentUpdated(publish, PaymentMethod.PAYPAL, controller.section);
                    }, 0);
                }
            }
            else {
                if (r.pendingPaymentInstrument) {
                    yield paymentInstrument.updatePendingPaymentInstrument(r.pendingPaymentInstrument);
                    if (r.pendingPaymentInstrument.payment_method === PaymentMethod.IDEAL) {
                        controller.setShowPaymentMethodsPanel(true, { disableAnimation: true });
                    }
                }
                cleanUrl();
                yield emitGlobalError(publish, PaymentInstrumentStatusFinalizer.name, { toaster: TEXT.NOT_FINALIZED });
            }
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [returnedStatusHandlerState.resultQueue.length]);
    return React.createElement(React.Fragment, null);
};
