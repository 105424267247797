import { ImageSizes } from '../domain/models';
import { getPaymentsAssetsPath } from '../services/ioc';
const FORMAT = 'png';
const SIZES_DENSITIES = {
    [ImageSizes.S]: 1,
    [ImageSizes.M]: 2,
    [ImageSizes.L]: 3,
};
export const getCDNImages = (path, defaultSize) => ({
    src: imageURL(path, defaultSize),
    srcSet: Object.values(ImageSizes)
        .map((size) => `${imageURL(path, size)} ${density(size)}`)
        .join(', '),
});
const imageURL = (path, size) => `${getPaymentsAssetsPath()}/images/${path}@${density(size)}.${FORMAT}`;
const density = (size) => `${SIZES_DENSITIES[size]}x`;
