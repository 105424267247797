var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { AsyncStorage, LocalAdapter } from '@lookiero/data-sources';
import React, { createContext, useEffect } from 'react';
import { StripeProvider, useSetupStripe, useStripeEngine } from '../../infrastructure/Stripe';
const PAYMENTS_ENGINE_KEY = 'payments__engine';
const storage = new AsyncStorage({ adapter: LocalAdapter });
const EngineContext = createContext({});
const useSetupEngine = useSetupStripe;
const useEngine = useStripeEngine;
const getEngineFromStorage = () => __awaiter(void 0, void 0, void 0, function* () { return (yield storage).get(PAYMENTS_ENGINE_KEY); });
const EngineProvider = (_a) => {
    var { children, engine } = _a, rest = __rest(_a, ["children", "engine"]);
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            (yield storage).set(PAYMENTS_ENGINE_KEY, engine);
        }))();
    }, [engine]);
    return (React.createElement(StripeProvider, Object.assign({ engine: engine }, rest), children));
};
export { EngineContext, EngineProvider, useEngine, useSetupEngine, getEngineFromStorage };
