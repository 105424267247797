var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { forwardRef, useImperativeHandle } from 'react';
import { usePaymentInstrument } from '../../../../hooks/usePaymentInstrument';
import { chargeRepository } from '../../../../infrastructure/repositories';
import { useEngine } from '../../../EngineProvider';
import { getDelayedHandlerForPendingPaymentMethod } from '../../accordions';
import { useController } from '../../PaymentInstrumentSelect.controller';
// TODO tiene sentido que esto sea un hook en vez de un componente?
export const DelayedHandler = forwardRef((props, ref) => {
    const controller = useController((s) => s);
    const { engineInstance } = useEngine();
    const paymentInstrument = usePaymentInstrument();
    const { pendingPaymentInstrument } = paymentInstrument;
    const chargePsService = (engine, paymentMethod, boxId) => __awaiter(void 0, void 0, void 0, function* () {
        return yield chargeRepository.chargePsService(engine, paymentMethod, boxId);
    });
    useImperativeHandle(ref, () => ({
        runDelayedHandling: ({ boxId }) => __awaiter(void 0, void 0, void 0, function* () {
            if (!engineInstance || !pendingPaymentInstrument)
                return;
            try {
                const chargePsServiceResponse = yield chargePsService(pendingPaymentInstrument.engine, pendingPaymentInstrument.payment_method, boxId);
                const delayedHandler = getDelayedHandlerForPendingPaymentMethod(pendingPaymentInstrument.payment_method, pendingPaymentInstrument.engine);
                yield (delayedHandler === null || delayedHandler === void 0 ? void 0 : delayedHandler({
                    controller,
                    metadata: {
                        clientSecret: chargePsServiceResponse.metadata.clientSecret,
                        chargeId: chargePsServiceResponse.chargeId,
                    },
                    currentPaymentInstruments: paymentInstrument,
                    engineInstance,
                }));
            }
            catch (err) {
                return Promise.reject(err);
            }
        }),
    }));
    return React.createElement(React.Fragment, null);
});
