var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getMessageForDeclineCode } from '../../../../domain/declineCodes';
import { PaymentMethod } from '../../../../domain/models';
import { PaymentFlowStrategy } from '../PaymentFlowStrategy';
import { ChargeStatus, CheckoutStatus, } from '../PaymentFlowStrategy.definition';
import { StrategyType } from './StrategyType';
export class CheckoutStrategy extends PaymentFlowStrategy {
    start(val) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { value: payload, stripe, section } = val;
            const paymentInstrument = yield this.getPaymentInstrument();
            try {
                this.paymentStatusState.init(section, {
                    paymentMethod: paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.payment_method,
                    chargeId: payload.checkoutUuid,
                });
                const reconciliationResult = yield this.executePaymentAttempt(payload.checkoutUuid, paymentInstrument);
                if (reconciliationResult.status !== CheckoutStatus.REQUIRES_ACTION) {
                    // In this case, the payment has been successfully completed if not,
                    // the data sources library returns an error caught by the catch block
                    this.successfullyCompletePaymentProcess(reconciliationResult, section);
                    return;
                }
                this.emit(CheckoutStatus.REQUIRES_ACTION);
                if ((paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.payment_method) === PaymentMethod.IDEAL) {
                    yield this.processPaymentExternally(reconciliationResult, payload, paymentInstrument, section, stripe);
                }
                else {
                    yield this.processPaymentWithSCA({
                        executePaymentAttemptResult: reconciliationResult,
                        section,
                        uuidToCancelInBackground: payload.checkoutUuid,
                    });
                }
            }
            catch (err) {
                this.resetState();
                const error = err;
                const messageDefinition = getMessageForDeclineCode(((_a = error === null || error === void 0 ? void 0 : error.payload) === null || _a === void 0 ? void 0 : _a.declineCode) || '');
                const metadata = (error === null || error === void 0 ? void 0 : error.payload) && {
                    id: error.payload.code,
                    translation: this.translate(messageDefinition),
                    toaster: messageDefinition,
                };
                void this.notifyUnexpectedError(error, { checkoutUuid: payload.checkoutUuid });
                this.paymentStatusState.finalize(section, {
                    success: false,
                    metadata,
                });
                this.emit(CheckoutStatus.ERROR, Object.assign({ final: true }, metadata));
            }
        });
    }
    getType() {
        return StrategyType.CHECKOUT;
    }
    executePaymentAttempt(checkoutUuid, paymentInstrument) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ui.loadingWhile(this.deps.checkoutAPI.reconcile(checkoutUuid, paymentInstrument));
            return {
                status: result.checkout.status,
                chargeId: (_a = result.charge) === null || _a === void 0 ? void 0 : _a.chargeId,
                declineCode: ((_b = result.charge) === null || _b === void 0 ? void 0 : _b.status) === ChargeStatus.REJECTED ? result.charge.declineCode : undefined,
                challengeUrl: result.checkout.status === CheckoutStatus.REQUIRES_ACTION && ((_c = result.charge) === null || _c === void 0 ? void 0 : _c.metadata.redirectToUrl_url)
                    ? result.charge.metadata.redirectToUrl_url
                    : undefined,
                challengeTimeout: result.checkout.timeout,
                clientSecret: (_d = result.charge) === null || _d === void 0 ? void 0 : _d.metadata.clientSecret,
            };
        });
    }
    confirmCharge(chargeId) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ui.challengeLoadingWhile(this.deps.checkoutAPI.confirmCharge({ chargeId }));
            return {
                status: result.checkout.status,
                declineCode: ((_a = result.charge) === null || _a === void 0 ? void 0 : _a.status) === ChargeStatus.REJECTED ? result.charge.declineCode : undefined,
            };
        });
    }
    cancelChargeInBackground(chargeId, checkoutUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                yield this.deps.checkoutAPI.cancelCharge(chargeId, 'cancelled by user');
            }
            catch (err) {
                void this.notifyUnexpectedError(err, { checkoutUuid });
            }
        });
    }
}
