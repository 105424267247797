export var TrackingEvents;
(function (TrackingEvents) {
    TrackingEvents["PAYMENT_SELECTION"] = "paymentSelection";
    TrackingEvents["PAYMENT_REGISTRATION"] = "paymentRegistration";
    TrackingEvents["CARD_INFO_ADDED"] = "cardInfoAdded";
    TrackingEvents["ADD_CARD_INFO"] = "addCardInfo";
    TrackingEvents["SINGLE_USE_PAYMENT_SAVED"] = "singleUsePaymentSaved";
    TrackingEvents["CARD_RETURN"] = "cardReturn";
    TrackingEvents["PAYPAL_RETURN"] = "paypalReturn";
    TrackingEvents["IDEAL_RETURN"] = "idealReturn";
})(TrackingEvents || (TrackingEvents = {}));
