import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    accordion: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        paddingVertical: '$space3',
        paddingHorizontal: '$space4',
    },
    logoContainer: {
        marginRight: '$space3',
    },
});
