import { Touchable, View } from '@lookiero/aurora';
import React from 'react';
import { usePaymentInstrument } from '../../../../hooks/usePaymentInstrument';
import { emitTrackingEvent } from '../../../../services/ioc';
import { TrackingEvents } from '../../../../tracking/events';
import { getAccordionForPaymentInstrument } from '../../accordions';
import { PaymentInstrumentAccordionContext } from '../../accordions/PaymentInstrumentAccordionContext';
import { useController } from '../../PaymentInstrumentSelect.controller';
import { usePaymentMethodsAvailability } from '../AvailableIntegrations/usePaymentMethodsAvailability';
import { style } from './CurrentPaymentMethod.styles';
export const CurrentPaymentMethod = (props) => {
    const paymentInstrument = usePaymentInstrument();
    const controller = useController((s) => s);
    const availability = usePaymentMethodsAvailability();
    const uniquePaymentMethodAvailable = Boolean(availability[props.paymentInstrument.payment_method].engine) &&
        Object.keys(availability).filter((key) => Boolean(availability[key].engine)).length === 1;
    const getComponent = () => {
        const { [props.paymentInstrument.payment_method]: paymentData } = availability;
        const engine = paymentData.engine || props.paymentInstrument.engine;
        const { Accordion, handler } = getAccordionForPaymentInstrument(props.paymentInstrument, engine);
        const onPressHandler = () => {
            var _a, _b;
            if (!uniquePaymentMethodAvailable) {
                controller.hideCardUpdaterPanel();
                controller.setShowAvailablePaymentMethodsPanel(true);
                return;
            }
            handler({ controller, engine });
            emitTrackingEvent(TrackingEvents.PAYMENT_SELECTION, {
                paymentMethod: props.paymentInstrument.payment_method,
                previousPaymentMethod: ((_a = paymentInstrument.pendingPaymentInstrument) === null || _a === void 0 ? void 0 : _a.payment_method) ||
                    ((_b = paymentInstrument.info) === null || _b === void 0 ? void 0 : _b.payment_method) ||
                    'none',
                section: controller.section,
            });
        };
        return (React.createElement(Touchable, { key: props.paymentInstrument.payment_method, role: "button", style: style.touchable, onPress: onPressHandler },
            React.createElement(PaymentInstrumentAccordionContext.Provider, { value: { editable: true } },
                React.createElement(View, { style: [style.container, controller.hasError && style.containerError] },
                    React.createElement(Accordion, { paymentInstrument: props.paymentInstrument })))));
    };
    return React.createElement(React.Fragment, null, getComponent());
};
