import { View } from '@lookiero/aurora';
import React, { useMemo } from 'react';
import { AmexCardSvg, ApplePaySvg, CardSvg, CBCardSvg, IdealSvg, MasterCardSvg, PaypalSvg, VisaSvg, } from './components';
import { style } from './PaymentInstrumentLogo.styles';
import { LogoName } from './types/LogoTypes';
const logos = {
    [LogoName.AMERICAN_EXPRESS]: AmexCardSvg,
    [LogoName.APPLE_PAY]: ApplePaySvg,
    [LogoName.CARD]: CardSvg,
    [LogoName.CARTES_BANCAIRES]: CBCardSvg,
    [LogoName.MASTERCARD]: MasterCardSvg,
    [LogoName.PAYPAL]: PaypalSvg,
    [LogoName.VISA]: VisaSvg,
    [LogoName.IDEAL]: IdealSvg,
};
export const PaymentInstrumentLogo = ({ logoName }) => {
    const Logo = useMemo(() => logos[logoName] || CardSvg, [logoName]);
    return (React.createElement(View, { style: style.logoContainer },
        React.createElement(Logo, null)));
};
