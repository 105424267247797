export const TEXT = {
    ACTION_RETURN: { id: 'payment.action.card_return' },
    CTA_UPDATE: { id: 'payment.cta.update' },
    CTA_RETRY: { id: 'payment.cta.retry' },
    CTA_BUSY: { id: 'payment.cta.busy' },
    INPUT_HINT_ERROR: { id: 'payment.card.emptyError' },
    INPUT_NUMBER: { id: 'account.payment.input' },
    INPUT_EXPIRATION_DATE: { id: 'account.payment.expiration' },
    INPUT_CVC: { id: 'account.payment.cvc' },
    NOTIFICATION_ERROR: { id: 'payment.notification.error_default' },
    NOTIFICATION_SUCCESS: { id: 'payment.notification.card_registration_success' },
    ERROR_UPDATE_CARD: { id: 'payment.error.paragraph_card_retry' },
    UPDATE_TITLE_CARD: { id: 'payment.modal.update_title_card' },
};
export var CardElementType;
(function (CardElementType) {
    CardElementType["CARD_NUMBER_ELEMENT"] = "CardNumberElement";
    CardElementType["CARD_EXPIRY_ELEMENT"] = "CardExpiryElement";
    CardElementType["CARD_CVC_ELEMENT"] = "CardCvcElement";
})(CardElementType || (CardElementType = {}));
