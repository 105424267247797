const ERROR_PAYMENT_CARD_DEFAULT = { id: 'payment.error.paragraph_card_standard' };
const PAYMENT_ERROR_GENERIC = { id: 'payment.notification.payment_error_generic' };
const MESSAGES_FOR_DECLINE_CODES = {
    authentication_acc_number_issue: ERROR_PAYMENT_CARD_DEFAULT,
    authentication_address_issue: ERROR_PAYMENT_CARD_DEFAULT,
    authentication_cvc_issue: { id: 'payment.error.paragraph_card_cvc' },
    authentication_expiration_date_issue: { id: 'payment.error.paragraph_card_expired_date' },
    authentication_pin_issue: ERROR_PAYMENT_CARD_DEFAULT,
    critical_issue: ERROR_PAYMENT_CARD_DEFAULT,
    duplicate_transaction_issue: { id: 'payment.error.paragraph_card_refresh' },
    method_expired_issue: { id: 'payment.error.paragraph_card_expired_date' },
    method_funds_issue: { id: 'payment.error.paragraph_card_insufficient_funds' },
    pending_payment_pay1: { id: 'payment.error.paragraph_pending_pay1' },
    pending_payment_pay2: { id: 'payment.error.paragraph_pending_pay2' },
    provider_currency_issue: { id: 'payment.error.paragraph_card_invalid_currency' },
    provider_issue: ERROR_PAYMENT_CARD_DEFAULT,
    provider_limit_issue: { id: 'payment.error.paragraph_card_exceeded_limit' },
    provider_pin_entries_issue: ERROR_PAYMENT_CARD_DEFAULT,
    provider_purchasetype_issue: ERROR_PAYMENT_CARD_DEFAULT,
    standard_issue: ERROR_PAYMENT_CARD_DEFAULT,
    test_issue: { id: 'payment.error.paragraph_card_test' },
    ideal_bank_not_available: { id: 'payment.error.ideal_bank_not_found' },
    error_generic: PAYMENT_ERROR_GENERIC,
};
export function getMessageForDeclineCode(declineCode) {
    if (!declineCode)
        return PAYMENT_ERROR_GENERIC;
    return MESSAGES_FOR_DECLINE_CODES[declineCode] || PAYMENT_ERROR_GENERIC;
}
export function getGenericPaymentErrorMessage() {
    return PAYMENT_ERROR_GENERIC;
}
