var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PaymentMethod, Section } from '../../../domain/models';
import { paymentInstrumentRepository } from '../../../infrastructure/repositories';
import { emitTrackingEvent, mustGetToken } from '../../../services/ioc';
import { paymentInstrumentFinalizerStore } from '../../../services/PaymentInstrumentFinalizer';
import { refreshCharge } from '../../../services/PaymentStatusRefresh';
import { TrackingEvents } from '../../../tracking/events';
import { isWeb } from '../../../utils/platform';
import { getReturnUrl } from '../../../utils/url';
import { openURL } from '../internals/linking';
import { idealBankSelectHandler } from './IdealAccordion/Ideal.controller';
export const cardAccordionHandler = ({ controller }) => {
    controller.showCardUpdaterPanel();
};
export const paypalAccordionHandler = ({ controller, engine, currentPaymentInstruments, }) => __awaiter(void 0, void 0, void 0, function* () {
    if (engine == null || controller.isRedirecting || paymentInstrumentFinalizerStore.getState().finalizing)
        return;
    controller.setIsRedirecting(true);
    controller.showPayPalRedirectionModal();
    const queryParams = {};
    queryParams['lk_section'] = controller.section;
    if (!(currentPaymentInstruments === null || currentPaymentInstruments === void 0 ? void 0 : currentPaymentInstruments.info) && (currentPaymentInstruments === null || currentPaymentInstruments === void 0 ? void 0 : currentPaymentInstruments.pendingPaymentInstrument))
        queryParams['pending_payment_instrument'] = JSON.stringify(currentPaymentInstruments.pendingPaymentInstrument);
    const returnUrl = yield getReturnUrl({
        controller,
        queryParams,
    });
    const result = yield paymentInstrumentRepository.createPayPalPaymentInstrument(returnUrl, yield mustGetToken(), engine);
    openURL(result.redirectionUrl);
    if (!isWeb) {
        setTimeout(() => {
            controller.setIsRedirecting(false);
            controller.hideAllPanels();
        }, 1000);
    }
});
export const idealAccordionHandler = ({ controller, paymentInstrument, engine, publish, translate, }) => __awaiter(void 0, void 0, void 0, function* () {
    if (!paymentInstrument || !publish || !translate)
        return;
    void paymentInstrument.updatePendingPaymentInstrument({
        engine,
        payment_method: PaymentMethod.IDEAL,
    });
    const emitPaymentSavedEvent = (output) => {
        emitTrackingEvent(TrackingEvents.SINGLE_USE_PAYMENT_SAVED, {
            paymentMethod: PaymentMethod.IDEAL,
            output,
            section: controller.section,
        });
    };
    try {
        yield idealBankSelectHandler({
            controller,
            publish,
            translate,
            shouldTokenizeAnotherPaymentInstrument: !paymentInstrument.info,
        });
        if (controller.section !== Section.BOX_OFON)
            emitPaymentSavedEvent('success');
    }
    catch (_a) {
        controller.hideLoadingPanel();
        emitPaymentSavedEvent('rejected');
    }
});
export const idealDelayedHandler = ({ controller, engineInstance, metadata, currentPaymentInstruments, }) => __awaiter(void 0, void 0, void 0, function* () {
    if (controller.isRedirecting)
        return;
    if (!(currentPaymentInstruments === null || currentPaymentInstruments === void 0 ? void 0 : currentPaymentInstruments.pendingPaymentInstrument) || !metadata.clientSecret)
        return;
    const { pendingPaymentInstrument } = currentPaymentInstruments;
    const returnUrl = yield getReturnUrl({
        controller,
        queryParams: {
            lk_section: controller.section,
            lk_charge_id: metadata.chargeId,
            lk_payment_method: PaymentMethod.IDEAL,
            pending_payment_instrument: JSON.stringify(pendingPaymentInstrument),
        },
    });
    const { clientSecret } = metadata;
    const billingDetails = controller.userInformation;
    controller.setIsRedirecting(true);
    const { paymentIntent, error } = yield engineInstance.confirmIdealPayment({
        clientSecret,
        data: { billingDetails, returnUrl },
    });
    if (error) {
        throw error;
    }
    if (!isWeb) {
        // in native we don't have an url redirect, so we execute the refresh here
        // we should maybe create a payments service that would encapsulate the stripe service in order to avoid if/else logic here and there
        yield refreshCharge({
            section: controller.section,
            engine: pendingPaymentInstrument.engine,
            paymentMethod: PaymentMethod.IDEAL,
            chargeId: metadata.chargeId,
            paymentResult: paymentIntent === null || paymentIntent === void 0 ? void 0 : paymentIntent.status,
        });
    }
    controller.setIsRedirecting(false);
    controller.hideAllPanels();
});
