import { useEffect, useRef, useState } from 'react';
const TIMEOUT_IN_MILLIS = 1000;
export const useAnimatedVisibility = (active) => {
    const [state, setState] = useState({
        visible: false,
        forceRender: false,
    });
    const timeoutId = useRef(null);
    useEffect(() => {
        if (active) {
            if (state.visible)
                return;
            timeoutId.current != null && clearTimeout(timeoutId.current);
            setState({ visible: true, forceRender: true });
        }
        else {
            if (!state.visible && !state.forceRender)
                return;
            setState((s) => (Object.assign(Object.assign({}, s), { visible: false })));
            timeoutId.current = setTimeout(() => setState({ visible: false, forceRender: false }), TIMEOUT_IN_MILLIS);
        }
        return () => {
            timeoutId.current != null && clearTimeout(timeoutId.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);
    return {
        isVisible: state.visible,
        isRendered: state.forceRender || active,
    };
};
