const wordPattern = new RegExp(['[A-Z][a-z]+', '[A-Z]+(?=[A-Z][a-z])', '[A-Z]+', '[a-z]+', '[0-9]+'].join('|'), 'g');
const words = (string = '', pattern) => {
    if (pattern === undefined) {
        return string.match(wordPattern) || [];
    }
    return string.match(pattern) || [];
};
const snakeCase = (string = '') => {
    return words(string)
        .map((word) => word.toLowerCase())
        .join('_');
};
export { snakeCase };
