var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getToken, useFeatureFlags } from '../../../services/ioc';
import { parseUserToken } from '../../../utils/parseUserToken';
import { cacheStorageService } from '../../CacheStorageService';
import { paymentInstrumentRepository } from '../../repositories';
import { queryClient } from '../TanStackQueryProvider';
const LONG_TTL = 15 * 60 * 1000; // 15 minutes
const SHORT_TTL = 1000 * 30; // 30 seconds
const PENDING_PAYMENT_INSTRUMENT = 'pending-payment-instrument';
const paymentInstrumentQueryName = 'paymentInstrument';
const composeQueryId = (queryName, uuid) => {
    if (!uuid)
        return [queryName];
    return [queryName].concat(uuid);
};
const getPaymentInstrumentQueryId = (uuid) => composeQueryId(paymentInstrumentQueryName, uuid);
const buildPendingPaymentInstrumentFromSingleUse = (paymentInstrumentInfo) => {
    if (!(paymentInstrumentInfo === null || paymentInstrumentInfo === void 0 ? void 0 : paymentInstrumentInfo.singleUse))
        return null;
    return {
        engine: paymentInstrumentInfo.engine,
        payment_method: paymentInstrumentInfo.singleUse.type,
        metadata: {},
    };
};
const getPendingPaymentInstrument = (uuid) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const cached = (_a = (yield cacheStorageService.get(PENDING_PAYMENT_INSTRUMENT))) === null || _a === void 0 ? void 0 : _a.value;
        if (cached) {
            return cached;
        }
        const paymentInstrumentData = yield queryClient.getQueryData(getPaymentInstrumentQueryId(uuid));
        return buildPendingPaymentInstrumentFromSingleUse(paymentInstrumentData);
    }
    catch (_b) {
        return null;
    }
});
const fetchPaymentInstrument = () => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const paymentInstrumentInfo = yield paymentInstrumentRepository.getPaymentInstrumentInfo();
    if (!paymentInstrumentInfo)
        return null;
    const backendPending = buildPendingPaymentInstrumentFromSingleUse(paymentInstrumentInfo);
    const cached = yield cacheStorageService.get(PENDING_PAYMENT_INSTRUMENT);
    const finalPending = (_c = backendPending !== null && backendPending !== void 0 ? backendPending : cached === null || cached === void 0 ? void 0 : cached.value) !== null && _c !== void 0 ? _c : null;
    return {
        paymentInstrument: {
            engine: paymentInstrumentInfo.engine,
            payment_method: paymentInstrumentInfo.payment_method,
            metadata: paymentInstrumentInfo.metadata,
        },
        pendingPaymentInstrument: finalPending,
    };
});
export const usePaymentInstrumentQuery = () => {
    var _a, _b;
    const [uuid, setUuid] = useState();
    const queryClient = useQueryClient();
    const featureFlags = useFeatureFlags();
    const ttl = featureFlags['USE_LARGE_TTL_IN_PAYMENTS_FRONT_CACHE'] ? LONG_TTL : SHORT_TTL;
    useEffect(() => {
        (() => __awaiter(void 0, void 0, void 0, function* () {
            const userToken = yield getToken();
            if (userToken) {
                setUuid(parseUserToken(userToken).uuid);
            }
        }))();
    }, []);
    const query = useQuery({
        queryKey: getPaymentInstrumentQueryId(uuid),
        queryFn: fetchPaymentInstrument,
        staleTime: ttl,
        cacheTime: ttl * 2,
    });
    const setPendingPaymentInstrument = (pending) => __awaiter(void 0, void 0, void 0, function* () {
        const token = yield getToken();
        if (!token)
            return;
        const uuid = parseUserToken(token).uuid;
        if (pending) {
            yield cacheStorageService.set(PENDING_PAYMENT_INSTRUMENT, pending);
        }
        else {
            yield cacheStorageService.remove(PENDING_PAYMENT_INSTRUMENT);
        }
        queryClient.setQueryData(getPaymentInstrumentQueryId(uuid), (oldData) => {
            const previous = oldData || { paymentInstrument: null, pendingPaymentInstrument: null };
            return Object.assign(Object.assign({}, previous), { pendingPaymentInstrument: pending });
        });
    });
    return {
        paymentInstrument: ((_a = query.data) === null || _a === void 0 ? void 0 : _a.paymentInstrument) || null,
        pendingPaymentInstrument: ((_b = query.data) === null || _b === void 0 ? void 0 : _b.pendingPaymentInstrument) || null,
        setPendingPaymentMethod: setPendingPaymentInstrument,
        loading: query.isLoading,
        refresh: () => __awaiter(void 0, void 0, void 0, function* () { return void queryClient.invalidateQueries(getPaymentInstrumentQueryId(uuid)); }),
    };
};
export const getPaymentInstrument = () => __awaiter(void 0, void 0, void 0, function* () {
    const token = yield getToken();
    if (!token)
        return null;
    const uuid = parseUserToken(token).uuid;
    const pendingPaymentMethod = (yield getPendingPaymentInstrument(uuid));
    const paymentInstrument = queryClient.getQueryData(getPaymentInstrumentQueryId(uuid));
    return pendingPaymentMethod || paymentInstrument || null;
});
