import { useEvent } from '@lookiero/event';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useEffect, useState } from 'react';
import { EngineContext } from '../../components/EngineProvider';
import { getStripeKey } from '../../services/ioc';
import { emitGlobalError } from '../../utils/eventEmitter';
import { getElementsOptions } from './StripeProvider.controller';
import { TEXT } from './StripeProvider.definition';
import { StripeService } from './StripeService';
const StripeProvider = ({ children, engine, locale, engineInstance: stripeInstance, }) => {
    const options = getElementsOptions(locale);
    return (React.createElement(EngineContext.Provider, { value: { engineInstance: stripeInstance, engine } },
        React.createElement(Elements, { stripe: stripeInstance.getStripeEngine(), options: options }, children)));
};
const useSetupStripe = (engine) => {
    const { publish } = useEvent();
    const [stripeInstance, setStripeInstance] = useState();
    useEffect(() => {
        if (!engine)
            return;
        const stripeKey = getStripeKey(engine);
        if (stripeInstance == null && stripeKey) {
            loadStripe(stripeKey).then((stripe) => {
                if (!stripe) {
                    emitGlobalError(publish, 'StripeProvider', { toaster: TEXT.ERROR_LOADING_STRIPE });
                    return;
                }
                setStripeInstance(new StripeService(stripe));
            });
        }
    }, [engine, publish, stripeInstance]);
    return stripeInstance;
};
const useStripeEngine = () => {
    return useContext(EngineContext);
};
export { EngineContext, StripeProvider, useStripeEngine, useSetupStripe };
