import React, { useEffect } from 'react';
import { styles } from './WebView.styles';
export const WebView = ({ url, onLoad, onMessage, style }) => {
    useEffect(() => {
        if (url)
            window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }, [url, onMessage]);
    return React.createElement("iframe", { style: Object.assign(Object.assign({}, style), styles.border), src: url, width: "100%", onLoad: onLoad });
};
