export var ChallengeResult;
(function (ChallengeResult) {
    ChallengeResult["CANCELED"] = "cancelled";
    ChallengeResult["FINISHED"] = "finished";
    ChallengeResult["TIMED_OUT"] = "timed_out";
})(ChallengeResult || (ChallengeResult = {}));
export var CheckoutStatus;
(function (CheckoutStatus) {
    CheckoutStatus["UNFULFILLED"] = "UNFULFILLED";
    CheckoutStatus["REJECTED"] = "REJECTED";
    CheckoutStatus["REQUIRES_ACTION"] = "REQUIRES_ACTION";
    CheckoutStatus["REQUIRED_ACTION_CANCELLED"] = "REQUIRED_ACTION_CANCELLED";
    CheckoutStatus["ERROR"] = "ERROR";
    CheckoutStatus["FULFILLED"] = "FULFILLED";
    CheckoutStatus["OVERPAID"] = "OVERPAID";
})(CheckoutStatus || (CheckoutStatus = {}));
export var ChargeStatus;
(function (ChargeStatus) {
    ChargeStatus["EXECUTED"] = "EXECUTED";
    ChargeStatus["REQUIRES_ACTION"] = "REQUIRES_ACTION";
    ChargeStatus["REQUIRED_ACTION_CANCELLED"] = "REQUIRED_ACTION_CANCELLED";
    ChargeStatus["REJECTED"] = "REJECTED";
    ChargeStatus["CANCELLED"] = "CANCELLED";
    ChargeStatus["TO_CONFIRM"] = "TO_CONFIRM";
    ChargeStatus["ERROR"] = "ERROR";
    ChargeStatus["UNKNOWN"] = "UNKNOWN";
})(ChargeStatus || (ChargeStatus = {}));
