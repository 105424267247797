import { Text, View, COLOR, SPACE } from '@lookiero/aurora';
import React from 'react';
import { useLocale } from '../../../../infrastructure/hooks/useLocale';
import { TEXT } from '../../PaymentInstrumentSelect.definition';
const ErrorBlock = () => {
    const { translate } = useLocale();
    return (React.createElement(View, { marginTop: SPACE.SPACE_025 },
        React.createElement(Text, { detail: true, level: 3, color: COLOR.TEXT_ERROR }, translate(TEXT.PAYMENT_METHOD_UNSELECTED_ERROR))));
};
export { ErrorBlock };
