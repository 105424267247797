import { COLOR, POINTER, POSITION, SIZE, styles, Theme } from '@lookiero/aurora';
import StyleSheet from 'react-native-extended-stylesheet';
const getInputHeight = () => StyleSheet.value('$inputSize') - StyleSheet.value('$borderSize') * 2;
const style = StyleSheet.create({
    border: {
        borderStyle: '$borderStyle',
        borderWidth: '$borderWidth1',
        height: getInputHeight,
        overflow: 'hidden',
        borderRadius: '$borderRadius3',
    },
    label: {
        width: 250,
    },
    labelFocus: {
        borderLeftWidth: '$space1',
        borderLeftColor: 'transparent',
        borderRightWidth: '$space1',
        borderRightColor: 'transparent',
        height: '$space4',
        paddingLeft: '$space1',
        paddingRight: '$space1',
        backgroundColor: '$colorBgBase',
        width: 'auto',
    },
    logo: {
        flex: 0,
        aspectRatio: undefined,
        height: '$space8',
    },
});
const getCardElementStyles = () => {
    const theme = Theme.get();
    return {
        style: {
            base: {
                color: theme.colorText,
                fontFeatureSettings: { liga: 'off', clig: 'off' },
                fontFamily: 'AreaNormal-Semibold, Arial',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '52px',
                letterSpacing: `${theme.fontBodyLetterSpacing2}px`,
                ':focus::placeholder': { color: theme.colorTextMediumLight },
            },
            invalid: {
                color: theme.colorText,
            },
        },
    };
};
const getElementBorderColor = (isFocusedElement, showError) => {
    if (showError)
        return COLOR.BORDER_INPUT_ERROR;
    if (isFocusedElement)
        return COLOR.BORDER_INPUT_FOCUS;
    return COLOR.BORDER_INTERACTIVE;
};
const getElementColor = (isFocusedElement, showError) => {
    if (showError)
        return COLOR.TEXT_ERROR;
    if (isFocusedElement)
        return COLOR.TEXT;
    return COLOR.TEXT_MEDIUM;
};
const getLabelProps = (isFocusedElement, isTouchedElement, showError) => ({
    as: 'label',
    backgroundColor: COLOR.BASE,
    color: getElementColor(isFocusedElement, showError),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style: styles(style.label, (isTouchedElement || isFocusedElement) && style.labelFocus),
    detail: isTouchedElement || isFocusedElement,
    ellipsizeMode: true,
    layer: SIZE.XS,
    level: isTouchedElement || isFocusedElement ? 2 : 3,
    pointer: POINTER.NONE,
    position: POSITION.ABSOLUTE,
});
export { getCardElementStyles, getElementBorderColor, getInputHeight, getLabelProps, style, styles };
