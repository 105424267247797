var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Engine, PaymentMethod } from '../../../../domain/models';
import { cacheStorageService } from '../../../../infrastructure/CacheStorageService';
import { paymentInstrumentRepository } from '../../../../infrastructure/repositories';
const HOURS_IN_MILLIS = 60 * 60 * 1000;
const CACHE_TTL = 2 * HOURS_IN_MILLIS;
const DEFAULT_INTEGRATIONS = [
    { engine: Engine.STRIPE, availableMethods: [PaymentMethod.CARD], singleUsePaymentMethods: [] },
];
export class AvailableIntegrationsService {
    constructor(storage, paymentInstrumentRepository) {
        this.storage = storage;
        this.paymentInstrumentRepository = paymentInstrumentRepository;
    }
    get(section, token, errEmitter) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheData = yield this.storage.get(section, CACHE_TTL);
            if (cacheData)
                return cacheData.value;
            const apiData = yield this.getFromAPI(section, token, errEmitter);
            if (apiData) {
                yield this.storage.set(section, apiData);
                return apiData;
            }
            yield this.storage.set(section, DEFAULT_INTEGRATIONS);
            return DEFAULT_INTEGRATIONS;
        });
    }
    getFromAPI(section, token, errEmitter) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                return yield this.paymentInstrumentRepository
                    .getAvailableIntegrations(section, token)
                    .then((d) => d.filter(integrationsWithKnownEngines));
            }
            catch (err) {
                errEmitter(err);
                return null;
            }
        });
    }
}
const integrationsWithKnownEngines = (i) => Object.values(Engine).includes(i.engine);
export const availableIntegrationsService = new AvailableIntegrationsService(cacheStorageService, paymentInstrumentRepository);
