var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PaymentMethod, Section } from '../../../../domain/models';
import { emitPaymentInstrumentSelectToDelayedHandling, internalEmitPaymentInstrumentUpdated, } from '../../../../utils/eventEmitter';
import { TEXT } from './ideal.definition';
const SECTIONS_WITH_LOADING_PANEL = [Section.BOX_CHECKOUT, Section.DIRECT_BUY_CHECKOUT, Section.BOX_PLACE_ORDER];
const LOADING_PANEL_TRANSITION_MS = 800;
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const idealBankSelectHandler = ({ controller, publish, translate, shouldTokenizeAnotherPaymentInstrument, }) => __awaiter(void 0, void 0, void 0, function* () {
    if (shouldTokenizeAnotherPaymentInstrument) {
        yield tokenizeAnotherPaymentInstrument({ controller, publish, translate });
    }
    else {
        if (SECTIONS_WITH_LOADING_PANEL.includes(controller.section)) {
            controller.showLoadingPanel();
            yield delay(LOADING_PANEL_TRANSITION_MS);
            controller.hideLoadingPanel();
        }
        yield selectIdealToDelayedHandling({ controller, publish, translate });
    }
});
const tokenizeAnotherPaymentInstrument = ({ controller }) => __awaiter(void 0, void 0, void 0, function* () {
    controller.setShowPaymentMethodsPanel(true);
});
const selectIdealToDelayedHandling = ({ controller, publish, translate }) => __awaiter(void 0, void 0, void 0, function* () {
    yield emitPaymentInstrumentSelectToDelayedHandling(publish, 'IdealAccordion', Object.assign(Object.assign({}, TEXT.NOTIFICATION_IDEAL_SELECTED), { translation: translate(TEXT.NOTIFICATION_IDEAL_SELECTED) }), { selected: true });
    internalEmitPaymentInstrumentUpdated(publish, PaymentMethod.IDEAL, controller.section);
    controller.setShowPaymentMethodsPanel(false);
    controller.setShowAvailablePaymentMethodsPanel(false);
});
