import React from 'react';
import Svg, { Path } from 'react-native-svg';
export const ApplePaySvg = ({ height = 40, width = 40 }) => {
    return (React.createElement(Svg, { width: width, height: height, viewBox: `0 0 ${width} ${height}`, fill: "none" },
        React.createElement(Path, { d: "M32.7058 8C34.5252 8 36 9.47484 36 11.2942V28.7058C36 30.5252 34.5252 32 32.7058 32H7.29415C5.47484 32 4 30.5252 4 28.7059V11.2941C4 9.47484 5.47484 8 7.29415 8H32.7058ZM7.29415 6C4.37027 6 2 8.37027 2 11.2941V28.7059C2 31.6297 4.37027 34 7.29415 34H32.7058C35.6297 34 38 31.6297 38 28.7058V11.2942C38 8.37027 35.6297 6 32.7058 6H7.29415Z", fill: "#DAD8D8" }),
        React.createElement(Path, { d: "M12.6508 16.94C12.9462 16.5793 13.1466 16.095 13.0938 15.6C12.6613 15.621 12.1336 15.8785 11.8281 16.2395C11.5538 16.5487 11.311 17.0533 11.3743 17.5275C11.8597 17.5686 12.3447 17.2906 12.6508 16.94Z", fill: "black" }),
        React.createElement(Path, { d: "M13.0882 17.6201C12.3833 17.5791 11.7839 18.0107 11.4472 18.0107C11.1104 18.0107 10.5948 17.6407 10.0372 17.6507C9.31151 17.6611 8.6381 18.0617 8.26982 18.6989C7.51232 19.9735 8.06991 21.8642 8.80654 22.9023C9.16426 23.4158 9.59538 23.9813 10.1634 23.961C10.7002 23.9405 10.9105 23.6217 11.5629 23.6217C12.2149 23.6217 12.4044 23.961 12.9725 23.9507C13.5617 23.9405 13.9301 23.4369 14.2878 22.9228C14.6982 22.3374 14.8661 21.7721 14.8767 21.741C14.8661 21.7308 13.7406 21.3092 13.7302 20.0452C13.7195 18.9868 14.6138 18.4834 14.6559 18.4522C14.1509 17.723 13.3618 17.6407 13.0882 17.6201Z", fill: "black" }),
        React.createElement(Path, { d: "M19.2266 16.1878C20.7588 16.1878 21.8258 17.2189 21.8258 18.7201C21.8258 20.2267 20.7369 21.2632 19.1881 21.2632H17.4917V23.8971H16.266V16.1878H19.2266V16.1878ZM17.4917 20.2588H18.8981C19.9652 20.2588 20.5726 19.6979 20.5726 18.7255C20.5726 17.7532 19.9652 17.1976 18.9036 17.1976H17.4917V20.2588Z", fill: "black" }),
        React.createElement(Path, { d: "M22.146 22.2997C22.146 21.3166 22.9176 20.7129 24.2858 20.6381L25.8618 20.5473V20.1146C25.8618 19.4895 25.4294 19.1155 24.7072 19.1155C24.023 19.1155 23.5961 19.436 23.4923 19.9383H22.3759C22.4416 18.9231 23.328 18.1752 24.7509 18.1752C26.1464 18.1752 27.0383 18.8965 27.0383 20.0237V23.8971H25.9055V22.9728H25.8782C25.5445 23.5979 24.8166 23.9932 24.0614 23.9932C22.9341 23.9932 22.146 23.3094 22.146 22.2997ZM25.8618 21.7922V21.3487L24.4444 21.4342C23.7384 21.4823 23.339 21.7868 23.339 22.2676C23.339 22.7591 23.7549 23.0797 24.3897 23.0797C25.216 23.0797 25.8618 22.524 25.8618 21.7922Z", fill: "black" }),
        React.createElement(Path, { d: "M28.1078 25.9647V25.0297C28.1952 25.051 28.3922 25.051 28.4908 25.051C29.038 25.051 29.3335 24.8267 29.514 24.2497C29.514 24.239 29.6181 23.9078 29.6181 23.9024L27.5387 18.2767H28.819L30.2749 22.85H30.2966L31.7524 18.2767H33.0001L30.8438 24.1909C30.3515 25.5533 29.7823 25.9914 28.5894 25.9914C28.4908 25.9914 28.1952 25.9806 28.1078 25.9647Z", fill: "black" })));
};
