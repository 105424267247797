import React from 'react';
import Svg, { Path } from 'react-native-svg';
export const PaypalSvg = ({ height = 40, width = 40 }) => {
    return (React.createElement(Svg, { width: width, height: height, viewBox: `0 0 ${width} ${height}`, fill: "none" },
        React.createElement(Path, { d: "M32.7058 8C34.5252 8 36 9.47484 36 11.2942V28.7058C36 30.5252 34.5252 32 32.7058 32H7.29415C5.47484 32 4 30.5252 4 28.7059V11.2941C4 9.47484 5.47484 8 7.29415 8H32.7058ZM7.29415 6C4.37027 6 2 8.37027 2 11.2941V28.7059C2 31.6297 4.37027 34 7.29415 34H32.7058C35.6297 34 38 31.6297 38 28.7058V11.2942C38 8.37027 35.6297 6 32.7058 6H7.29415Z", fill: "#DAD8D8" }),
        React.createElement(Path, { d: "M9.74072 23C9.67598 23 9.62099 23.047 9.61087 23.111L8.86147 27.8635C8.85875 27.8808 8.85982 27.8986 8.86461 27.9155C8.8694 27.9324 8.87779 27.948 8.88921 27.9614C8.90063 27.9748 8.91481 27.9855 8.93077 27.9928C8.94673 28.0002 8.96409 28.004 8.98167 28.004H9.87039C9.93504 28.004 9.99012 27.9569 10.0002 27.893L10.2204 26.4974C10.2304 26.4335 10.2855 26.3864 10.3502 26.3864H11.1582C12.1023 26.3864 12.9037 25.6976 13.0501 24.7642C13.1977 23.8229 12.4623 23.002 11.4201 23H9.74072ZM10.6046 24.0609H11.2511C11.7834 24.0609 11.9567 24.375 11.9019 24.7223C11.8471 25.0703 11.5779 25.3267 11.063 25.3267H10.405L10.6046 24.0609ZM14.8577 24.384C14.6333 24.3848 14.375 24.4308 14.0851 24.5516C13.4202 24.8287 13.1008 25.4015 12.9652 25.8192C12.9652 25.8192 12.5336 27.0933 13.5088 27.7932C13.5088 27.7932 14.4132 28.4671 15.4313 27.7517L15.4138 27.8635C15.411 27.8808 15.4121 27.8985 15.4169 27.9154C15.4217 27.9323 15.43 27.948 15.4414 27.9613C15.4528 27.9747 15.467 27.9854 15.4829 27.9928C15.4989 28.0001 15.5162 28.004 15.5338 28.004H16.3774C16.4422 28.004 16.4972 27.9569 16.5073 27.893L17.0205 24.6385C17.0232 24.6211 17.0222 24.6034 17.0174 24.5865C17.0127 24.5696 17.0043 24.5539 16.9929 24.5405C16.9815 24.5271 16.9673 24.5164 16.9514 24.509C16.9354 24.5016 16.9181 24.4978 16.9005 24.4978H16.0568C15.9921 24.4978 15.937 24.5449 15.927 24.6088L15.8994 24.7839C15.8994 24.7839 15.531 24.3815 14.8577 24.384ZM14.8852 25.4119C14.9821 25.4119 15.0707 25.4253 15.1503 25.4509C15.5143 25.5677 15.7207 25.9174 15.6609 26.2965C15.5873 26.7632 15.2045 27.1069 14.7136 27.1069C14.6168 27.1069 14.5282 27.0936 14.4486 27.0681C14.0847 26.9512 13.877 26.6015 13.9367 26.2225C14.0104 25.7557 14.3944 25.4119 14.8852 25.4119Z", fill: "#003087" }),
        React.createElement(Path, { d: "M21.8901 23C21.8253 23 21.7703 23.047 21.7602 23.111L21.0108 27.8635C21.0081 27.8808 21.0092 27.8986 21.014 27.9155C21.0188 27.9324 21.0271 27.948 21.0386 27.9614C21.05 27.9748 21.0642 27.9855 21.0801 27.9928C21.0961 28.0002 21.1135 28.004 21.131 28.004H22.0197C22.0844 28.004 22.1395 27.9569 22.1496 27.893L22.3697 26.4974C22.3798 26.4335 22.4349 26.3864 22.4996 26.3864H23.3076C24.2517 26.3864 25.053 25.6976 25.1993 24.7642C25.347 23.8229 24.6117 23.002 23.5694 23H21.8901ZM22.754 24.0609H23.4005C23.9327 24.0609 24.106 24.375 24.0512 24.7223C23.9964 25.0703 23.7273 25.3267 23.2124 25.3267H22.5544L22.754 24.0609ZM27.0069 24.384C26.7825 24.3848 26.5243 24.4308 26.2344 24.5516C25.5694 24.8287 25.2501 25.4015 25.1145 25.8192C25.1145 25.8192 24.683 27.0933 25.6582 27.7932C25.6582 27.7932 26.5625 28.4671 27.5807 27.7517L27.5631 27.8635C27.5604 27.8808 27.5615 27.8986 27.5663 27.9155C27.571 27.9324 27.5794 27.948 27.5909 27.9614C27.6023 27.9748 27.6165 27.9855 27.6324 27.9928C27.6484 28.0002 27.6657 28.004 27.6833 28.004H28.5269C28.5916 28.004 28.6466 27.9569 28.6568 27.893L29.1701 24.6385C29.1729 24.6211 29.1718 24.6034 29.1671 24.5864C29.1623 24.5695 29.1539 24.5538 29.1425 24.5404C29.131 24.527 29.1169 24.5163 29.1009 24.5089C29.0849 24.5016 29.0675 24.4978 29.0499 24.4978H28.2063C28.1415 24.4978 28.0864 24.5449 28.0764 24.6088L28.0488 24.7839C28.0488 24.7839 27.6803 24.3815 27.0069 24.384ZM27.0345 25.4119C27.1314 25.4119 27.22 25.4253 27.2995 25.4509C27.6635 25.5677 27.8699 25.9174 27.8102 26.2965C27.7365 26.7632 27.3538 27.1069 26.8629 27.1069C26.7661 27.1069 26.6775 27.0936 26.5979 27.0681C26.2339 26.9512 26.0263 26.6015 26.086 26.2225C26.1596 25.7557 26.5436 25.4119 27.0345 25.4119Z", fill: "#0070E0" }),
        React.createElement(Path, { d: "M17.4208 24.4978C17.354 24.4978 17.3064 24.5631 17.3268 24.6267L18.2518 27.4969L17.4154 28.8495C17.3748 28.9152 17.422 29 17.4992 29H18.4877C18.516 29 18.5438 28.9927 18.5684 28.9788C18.593 28.9649 18.6136 28.9449 18.6282 28.9207L21.2115 24.6473C21.2511 24.5816 21.2038 24.4977 21.127 24.4977H20.1386C20.1101 24.4977 20.0821 24.5052 20.0573 24.5193C20.0325 24.5335 20.0118 24.5538 19.9973 24.5784L18.9805 26.2951L18.4643 24.5911C18.4475 24.5357 18.3963 24.4977 18.3384 24.4977L17.4208 24.4978Z", fill: "#003087" }),
        React.createElement(Path, { d: "M30.1287 23C30.064 23 30.009 23.0471 29.9988 23.111L29.2493 27.8633C29.2465 27.8806 29.2476 27.8984 29.2523 27.9153C29.2571 27.9323 29.2655 27.948 29.2769 27.9613C29.2883 27.9747 29.3025 27.9855 29.3185 27.9928C29.3345 28.0002 29.3519 28.004 29.3695 28.004H30.2582C30.3228 28.004 30.3779 27.9569 30.388 27.893L31.1374 23.1405C31.1401 23.1232 31.1391 23.1055 31.1343 23.0886C31.1295 23.0717 31.1212 23.056 31.1097 23.0427C31.0983 23.0293 31.0842 23.0186 31.0683 23.0112C31.0523 23.0039 31.035 23 31.0174 23H30.1287Z", fill: "#0070E0" }),
        React.createElement(Path, { d: "M23.6038 13.5213C23.6016 13.6359 23.5915 13.7502 23.5738 13.8635C23.3359 15.3804 22.0337 16.4998 20.4994 16.4998H18.9747C18.8188 16.4998 18.6858 16.6133 18.6616 16.7675L18.1832 19.7999L17.8835 21.7023C17.8777 21.7391 17.8798 21.7766 17.8899 21.8125C17.9 21.8483 17.9178 21.8815 17.9419 21.9098C17.9661 21.9381 17.9961 21.9608 18.0299 21.9764C18.0637 21.992 18.1005 22 18.1377 22H19.7926C19.8681 21.9999 19.9411 21.9729 19.9985 21.9239C20.0559 21.8748 20.0939 21.8069 20.1057 21.7323L20.5416 18.9676C20.5535 18.893 20.5915 18.825 20.649 18.7759C20.7064 18.7269 20.7795 18.6999 20.855 18.6999H21.8294C23.3638 18.6999 24.6659 17.5805 24.9038 16.0636C25.0728 14.9869 24.5305 14.0072 23.6038 13.5213Z", fill: "#0070E0" }),
        React.createElement(Path, { d: "M17.615 11C17.459 11 17.3261 11.1134 17.3018 11.2674L16.0032 19.5022C15.9786 19.6585 16.0994 19.7999 16.2577 19.7999H18.1834L18.6616 16.7675L19.1819 13.4675C19.1937 13.3929 19.2317 13.325 19.2891 13.2759C19.3465 13.2269 19.4195 13.1999 19.495 13.1998H22.2539C22.7559 13.1998 23.2139 13.317 23.6039 13.5213C23.6306 12.1402 22.4909 11 20.9241 11H17.615Z", fill: "#003087" }),
        React.createElement(Path, { d: "M19.495 13.1997C19.4195 13.1998 19.3465 13.2268 19.2891 13.2758C19.2317 13.3249 19.1937 13.3928 19.1819 13.4674L18.6616 16.7674C18.6858 16.6133 18.8186 16.4997 18.9747 16.4997H20.4994C22.0338 16.4997 23.3359 15.3803 23.5738 13.8634C23.5915 13.7501 23.6016 13.6358 23.6038 13.5212C23.2139 13.3167 22.7558 13.1997 22.2539 13.1997H19.495Z", fill: "#001C64" })));
};
