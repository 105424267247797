var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getMessageForDeclineCode } from '../../../domain/declineCodes';
import { getPaymentInstrument as getPaymentInstrumentFromQuery } from '../../../hooks/usePaymentInstrument';
import { getHostUrl } from '../../../services/ioc';
import { store } from '../../../services/PaymentStatusManager';
import { refreshCharge } from '../../../services/PaymentStatusRefresh';
import assertUnreachable from '../../../utils/assertUnreachable';
import { emitGlobalErrorAndMonitor } from '../../../utils/eventEmitter';
import { isWeb } from '../../../utils/platform';
import { ChallengeResult, ChargeStatus, CheckoutStatus, } from './PaymentFlowStrategy.definition';
import { PaymentFlowUI } from './PaymentFlowUI';
import { mutator } from './utils/mutator';
const TEXT = {
    NOTIFICATION_PAYMENT_SUCCESS: { id: 'payment.notification.success_retry_payment' },
};
export class PaymentFlowStrategy {
    constructor(baseDeps) {
        this.TIMEOUT_MARGIN_IN_SECONDS = 15;
        this.DEFAULT_TIMEOUT_IN_SECONDS = 300;
        this.paymentStatusState = store.getState();
        this.globalEventEmitter = baseDeps.globalEventEmitter;
        this.resetState = baseDeps.resetState;
        // @deprecated - This should be removed in the future
        this.emitter = baseDeps.emitter;
        this.deps = baseDeps.deps;
        this.ui = new PaymentFlowUI(baseDeps.set);
        this.translate = baseDeps.translate;
    }
    getPaymentInstrument() {
        return __awaiter(this, void 0, void 0, function* () {
            const paymentInstrument = yield getPaymentInstrumentFromQuery();
            if (!paymentInstrument) {
                this.resetState();
                this.emit(ChargeStatus.ERROR, { final: true });
                throw new Error('No payment instrument found');
            }
            return paymentInstrument;
        });
    }
    notifyUnexpectedError(err, context) {
        return __awaiter(this, void 0, void 0, function* () {
            void emitGlobalErrorAndMonitor(this.globalEventEmitter, 'PaymentFlow', err, Object.assign({ process: 'payment_flow' }, context));
        });
    }
    composeReturnUrlForExternalPayment(chargeId, payload, paymentInstrument, section) {
        const returnUrl = `${getHostUrl()}/${payload.returnUrl}`.replace(/([^:]\/)\/+/g, '$1');
        const queryString = new URLSearchParams({
            lk_payment_method: paymentInstrument.payment_method,
            lk_section: section,
            lk_charge_id: chargeId,
            lk_payment_strategy: this.getType(),
        }).toString();
        const separator = returnUrl.includes('?') ? '&' : '?';
        return queryString ? `${returnUrl}${separator}${queryString}` : returnUrl;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cancelChargeInBackground(chargeId, checkoutUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve();
        });
    }
    processPaymentWithSCA({ executePaymentAttemptResult, uuidToCancelInBackground, section, }) {
        return __awaiter(this, void 0, void 0, function* () {
            const challengeTimeout = executePaymentAttemptResult.challengeTimeout || this.DEFAULT_TIMEOUT_IN_SECONDS;
            const challengeResult = yield this.ui.displayChallenge(executePaymentAttemptResult.challengeUrl, challengeTimeout - this.TIMEOUT_MARGIN_IN_SECONDS);
            const chargeId = executePaymentAttemptResult.chargeId;
            if (challengeResult === ChallengeResult.CANCELED) {
                uuidToCancelInBackground && (yield this.cancelChargeInBackground(chargeId, uuidToCancelInBackground));
                this.paymentStatusState.finalize(section, {
                    success: false,
                });
                return this.emit(CheckoutStatus.REQUIRED_ACTION_CANCELLED, { final: true });
            }
            if (challengeResult === ChallengeResult.TIMED_OUT) {
                const messageDefinition = { id: 'payment.error.timeout2fa' };
                const metadata = {
                    toaster: messageDefinition,
                    id: messageDefinition.id,
                    translation: this.translate(messageDefinition),
                };
                this.paymentStatusState.finalize(section, {
                    success: false,
                    metadata,
                });
                return this.emit(CheckoutStatus.REQUIRED_ACTION_CANCELLED, Object.assign({ final: true }, metadata));
            }
            if (challengeResult === ChallengeResult.FINISHED) {
                const confirmChargeResult = yield this.confirmCharge(chargeId);
                this.successfullyCompletePaymentProcess(confirmChargeResult, section);
                return;
            }
            assertUnreachable(challengeResult);
        });
    }
    processPaymentExternally(executePaymentAttemptResult, payload, paymentInstrument, section, stripe) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const chargeId = executePaymentAttemptResult.chargeId;
            const returnUrl = this.composeReturnUrlForExternalPayment(chargeId, payload, paymentInstrument, section);
            const result = yield stripe.confirmIdealPayment({
                clientSecret: executePaymentAttemptResult.clientSecret,
                data: {
                    billingDetails: payload.userInformation,
                    returnUrl,
                },
            });
            if (result.error) {
                const messageDefinition = getMessageForDeclineCode((_a = result.error.payload) === null || _a === void 0 ? void 0 : _a.code);
                // @deprecated - This should be removed in the future
                this.emit(CheckoutStatus.ERROR, {
                    final: true,
                    toaster: messageDefinition,
                });
                this.paymentStatusState.finalize(section, {
                    success: false,
                    metadata: {
                        id: messageDefinition.id,
                        translation: this.translate(messageDefinition),
                        toaster: messageDefinition,
                    },
                });
                return;
            }
            if (!isWeb) {
                yield refreshCharge({
                    chargeId,
                    engine: paymentInstrument.engine,
                    paymentMethod: paymentInstrument.payment_method,
                    paymentResult: (_b = result.paymentIntent) === null || _b === void 0 ? void 0 : _b.status,
                    section,
                });
            }
        });
    }
    successfullyCompletePaymentProcess(executePaymentAttemptResult, section) {
        const messageDefinition = executePaymentAttemptResult.declineCode
            ? getMessageForDeclineCode(executePaymentAttemptResult.declineCode)
            : TEXT.NOTIFICATION_PAYMENT_SUCCESS;
        this.paymentStatusState.finalize(section, {
            success: [CheckoutStatus.FULFILLED, ChargeStatus.EXECUTED].includes(executePaymentAttemptResult.status),
            metadata: {
                id: messageDefinition.id,
                translation: messageDefinition ? this.translate(messageDefinition) : '',
                toaster: messageDefinition,
            },
        });
        // @deprecated - This should be removed in the future
        this.emit(executePaymentAttemptResult.status, {
            final: true,
            toaster: messageDefinition,
            id: messageDefinition === null || messageDefinition === void 0 ? void 0 : messageDefinition.id,
            translation: messageDefinition ? this.translate(messageDefinition) : '',
        });
    }
    emit(status, extra) {
        this.emitter({
            status,
            final: (extra === null || extra === void 0 ? void 0 : extra.final) || false,
            toaster: (extra === null || extra === void 0 ? void 0 : extra.toaster) || null,
            id: extra === null || extra === void 0 ? void 0 : extra.id,
            translation: extra === null || extra === void 0 ? void 0 : extra.translation,
        });
    }
}
export function strategyBuilder(strategyDeps) {
    return (clazz, deps) => {
        // @deprecated - emit callback should be removed in the future
        return (value, emit) => new clazz({
            set: mutator(strategyDeps.setState),
            resetState: () => strategyDeps.setState(strategyDeps.initialState),
            emitter: emit ? emit : () => { },
            globalEventEmitter: strategyDeps.emitGlobalError,
            deps,
            translate: strategyDeps.translate,
        }).start({
            value,
            stripe: strategyDeps.stripe,
            section: strategyDeps.section,
        });
    };
}
