import React from 'react';
import Svg, { Path } from 'react-native-svg';
export const CardSvg = ({ height = 40, width = 40 }) => {
    return (React.createElement(Svg, { width: width, height: height, viewBox: `0 0 ${width} ${height}`, fill: "none" },
        React.createElement(Path, { d: "M32.7058 8C34.5252 8 36 9.47484 36 11.2942V28.7058C36 30.5252 34.5252 32 32.7058 32H7.29415C5.47484 32 4 30.5252 4 28.7059V11.2941C4 9.47484 5.47484 8 7.29415 8H32.7058ZM7.29415 6C4.37027 6 2 8.37027 2 11.2941V28.7059C2 31.6297 4.37027 34 7.29415 34H32.7058C35.6297 34 38 31.6297 38 28.7058V11.2942C38 8.37027 35.6297 6 32.7058 6H7.29415Z", fill: "#DAD8D8" }),
        React.createElement(Path, { d: "M36 12H4V17H36V12Z", fill: "#0C0A0A" }),
        React.createElement(Path, { d: "M20 22H7V24H20V22Z", fill: "#0C0A0A" }),
        React.createElement(Path, { d: "M15 26H7V28H15V26Z", fill: "#0C0A0A" }),
        React.createElement(Path, { d: "M28 22H33V24H28V22Z", fill: "#0C0A0A" })));
};
