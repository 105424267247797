var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import PKG from '../../package.json';
export function emitMonitoringError(publish, component, err, context) {
    return __awaiter(this, void 0, void 0, function* () {
        publish({ domain: 'PAYMENTS', event: 'MONITORING.ERROR' }, {
            error: err,
            package: PKG.name,
            version: PKG.version,
            component: getComponentName(component),
            context: Object.assign({}, context),
        });
    });
}
export function emitGlobalError(publish, component, err, context) {
    return __awaiter(this, void 0, void 0, function* () {
        publish({ event: 'ERROR' }, {
            error: err,
            package: PKG.name,
            version: PKG.version,
            component: getComponentName(component),
            context: Object.assign({}, context),
        });
    });
}
export function emitGlobalErrorAndMonitor(publish, component, err, context) {
    return __awaiter(this, void 0, void 0, function* () {
        void emitMonitoringError(publish, getComponentName(component), err, context);
        void emitGlobalError(publish, getComponentName(component), err, context);
    });
}
export function emitPaymentInstrumentUpdated(publish, component, message, paymentMethod, context) {
    return __awaiter(this, void 0, void 0, function* () {
        publish({ event: 'PAYMENT_INSTRUMENT_UPDATED' }, {
            id: message.id,
            message,
            paymentMethod,
            package: PKG.name,
            version: PKG.version,
            component: getComponentName(component),
            context: Object.assign({}, context),
        });
    });
}
export function internalEmitPaymentInstrumentUpdated(publish, paymentMethod, section) {
    publish({ event: 'INTERNAL_PAYMENT_INSTRUMENT_UPDATED' }, { paymentMethod, section });
}
export function emitPaymentInstrumentSelectToDelayedHandling(publish, component, message, context) {
    return __awaiter(this, void 0, void 0, function* () {
        publish({ event: 'PAYMENT_INSTRUMENT_SELECTED_TO_DELAYED_HANDLING' }, {
            id: message.id,
            message,
            package: PKG.name,
            version: PKG.version,
            component: getComponentName(component),
            context: Object.assign({}, context),
        });
    });
}
export function emitPaymentSuccess(publish, component, message, paymentMethod, context) {
    publish({ event: 'PAYMENT_SUCCESS' }, {
        id: message.id,
        message,
        paymentMethod,
        package: PKG.name,
        version: PKG.version,
        component: getComponentName(component),
        context: Object.assign({}, context),
    });
}
export function emitPaymentError(publish, component, message, paymentMethod, context) {
    publish({ event: 'PAYMENT_ERROR' }, {
        id: message.id,
        message,
        paymentMethod,
        package: PKG.name,
        version: PKG.version,
        component: getComponentName(component),
        context: Object.assign({}, context),
    });
}
function getComponentName(component) {
    return typeof component === 'string' ? component : component.displayName;
}
