import { ALIGN, COLOR, Header, Panel, SPACE } from '@lookiero/aurora';
import React from 'react';
import { useAnimatedVisibility } from '../../hooks/useAnimatedVisibility';
import { useScroller } from '../../hooks/useScroller';
import { getScrollView } from '../../services/ioc';
import { Portal } from '../Portal';
export const PaymentPanel = (props) => {
    var _a;
    const { enableBackgroundScroll, disableBackgroundScroll } = useScroller();
    const { isVisible } = useAnimatedVisibility(props.isVisible);
    const ScrollView = getScrollView();
    const onClose = (_a = props.onClose) !== null && _a !== void 0 ? _a : (() => { });
    isVisible ? disableBackgroundScroll() : enableBackgroundScroll();
    return (React.createElement(Portal, null,
        React.createElement(Panel, { isVisible: isVisible, align: ALIGN.RIGHT, color: COLOR.BASE, testID: 'payment-panel-' + props.id, onClose: onClose, disableAnimation: props.disableAnimation }, props.renderChildrenOnly ? (React.createElement(React.Fragment, null, props.children)) : (React.createElement(ScrollView, { stickyHeaderIndices: [0], stickyHeaderHiddenOnScroll: false, scrollEnabled: true, keyboardShouldPersistTaps: "handled", contentContainerStyle: { flexGrow: 1 } },
            React.createElement(Header, { color: COLOR.BASE, onBack: props.onClose, title: props.title, level: 3, marginBottom: SPACE.SPACE_6 }),
            props.children)))));
};
