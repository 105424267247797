/**
 * Callback is a generic type that allows creating an structure that contains
 * both the function to be called and the resulting promise, so you can
 * subscribe to the value being received.
 *
 * const cb = callback<string>()
 *
 * cb.promise.then((name) => console.log(name))
 * cb.call("Peter")
 *
 * Outputs: "Peter"
 */
export const callback = () => {
    let call = () => { };
    const promise = new Promise((resolve) => (call = resolve));
    return { call, promise };
};
