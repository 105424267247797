export const TEXT = {
    CARD: { id: 'payment.method.card' },
    CREDIT_CARD: { id: 'payment.method.credit_card' },
    IDEAL: { id: 'payment.method.ideal' },
    IDEAL_NOTIFICATION_SUCCESS: { id: 'payment.notification.ideal_setup_success' },
    PANEL_CARD_TITLE: { id: 'payment.method.title_card' },
    PANEL_PAYMENT_METHOD_SELECT_TITLE: { id: 'payment.method.title' },
    PANEL_PAYMENT_METHOD_SELECT_BODY: { id: 'payment.method.select' },
    PAYMENT_METHOD: { id: 'payment.method.credit_card' },
    PAYMENT_METHOD_PAYPAL_DISCLAIMER: { id: 'payment.method.paypal_disclaimer' },
    PAYMENT_METHOD_UNSELECTED_ERROR: { id: 'payment.method.unselected_error' },
    PAYPAL: { id: 'payment.method.paypal_title' },
    NOT_FINALIZED: { id: 'payment.notification.not-finalized' },
    NOTIFICATION_SUCCESS: { id: 'payment.notification.paypal_tokenization_success' },
    SINGLE_PAYMENT_METHODS_TITLE: { id: 'payment.checkout.ideal_alternative' },
};
