import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    logoContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    logo: {
        height: 32,
        width: 48,
    },
});
