import { Spinner, View } from '@lookiero/aurora';
import React, { useContext } from 'react';
import { PaymentInstrumentSelectControllerContext, useController, } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { PaymentPanel } from '../PaymentPanel';
import { style } from './LoadingPanel.styles';
export const LoadingPanel = () => {
    const storeInstance = useContext(PaymentInstrumentSelectControllerContext);
    const controller = useController((s) => s);
    const { visible } = controller.panelVisibility.loadingPanel;
    return (React.createElement(PaymentPanel, { id: "LoadingPanel", isVisible: visible, disableAnimation: true, renderChildrenOnly: true },
        React.createElement(PaymentInstrumentSelectControllerContext.Provider, { value: storeInstance },
            React.createElement(View, { style: style.container },
                React.createElement(Spinner, null)))));
};
