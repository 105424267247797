import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import create from 'zustand';
const hideAllPanels = (panelVisibility) => {
    return Object.assign({}, Object.keys(panelVisibility).reduce((acc, key) => {
        acc[key] = { visible: false };
        return acc;
    }, {}));
};
const showPanel = (panelVisibility, panelToShow) => {
    return Object.assign(Object.assign({}, hideAllPanels(panelVisibility)), { [panelToShow]: { visible: true } });
};
export const controllerFactory = (params) => create((set) => (Object.assign(Object.assign({}, params), { panelVisibility: {
        cardUpdater: { visible: false },
        paymentMethodsSelect: { visible: false },
        availablePaymentMethodsSelect: { visible: false },
        paypalRedirectionModal: { visible: false },
        loadingPanel: { visible: false },
    }, isRedirecting: false, pendingPaymentInstrument: null, paymentInstrumentLoading: false, showCardUpdaterPanel: () => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: Object.assign(Object.assign({}, s.panelVisibility), { cardUpdater: { visible: true } }) }))), hideCardUpdaterPanel: () => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: Object.assign(Object.assign({}, s.panelVisibility), { cardUpdater: { visible: false } }) }))), setShowPaymentMethodsPanel: (visible, opts = {}) => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: Object.assign(Object.assign({}, s.panelVisibility), { paymentMethodsSelect: Object.assign({ visible }, opts) }) }))), setShowAvailablePaymentMethodsPanel: (visible, opts = {}) => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: Object.assign(Object.assign({}, s.panelVisibility), { availablePaymentMethodsSelect: Object.assign({ visible }, opts) }) }))), setIsRedirecting: (redirecting) => set((s) => (Object.assign(Object.assign({}, s), { isRedirecting: redirecting }))), hideAllPanels: () => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: hideAllPanels(s.panelVisibility) }))), showPayPalRedirectionModal: () => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: showPanel(s.panelVisibility, 'paypalRedirectionModal') }))), hidePayPalRedirectionModal: () => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: Object.assign(Object.assign({}, s.panelVisibility), { paypalRedirectionModal: { visible: false } }) }))), showLoadingPanel: () => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: Object.assign(Object.assign({}, s.panelVisibility), { loadingPanel: { visible: true } }) }))), hideLoadingPanel: () => set((s) => (Object.assign(Object.assign({}, s), { panelVisibility: Object.assign(Object.assign({}, s.panelVisibility), { loadingPanel: { visible: false } }) }))) })));
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const PaymentInstrumentSelectControllerContext = createContext(null);
export const useControllerFactory = (params) => {
    const [useControllerInstance] = useState(() => controllerFactory(params));
    useEffect(() => {
        const state = useControllerInstance.getState();
        if (params.section === state.section &&
            params.hasError === state.hasError &&
            params.userInformation === state.userInformation &&
            params.hidePaymentMethods.join(',') === state.hidePaymentMethods.join(',') &&
            params.showSingleUsePaymentMethods === state.showSingleUsePaymentMethods &&
            params.beforeRedirectCallback === state.beforeRedirectCallback &&
            JSON.stringify(params.availableIntegrations) === JSON.stringify(state.availableIntegrations))
            return;
        useControllerInstance.setState((s) => (Object.assign(Object.assign({}, s), params)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        params.section,
        params.hasError,
        params.userInformation,
        params.hidePaymentMethods,
        params.showSingleUsePaymentMethods,
        params.beforeRedirectCallback,
        params.availableIntegrations,
    ]);
    return useCallback(({ children }) => {
        return (React.createElement(PaymentInstrumentSelectControllerContext.Provider, { value: useControllerInstance }, children));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
export function useController(selector) {
    const useControllerInstance = useContext(PaymentInstrumentSelectControllerContext);
    return useControllerInstance(selector);
}
