var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Touchable } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import React from 'react';
import { getAccordion } from '../..';
import { getMessageForDeclineCode } from '../../../../../domain/declineCodes';
import { PaymentMethod } from '../../../../../domain/models';
import { usePaymentInstrument } from '../../../../../hooks/usePaymentInstrument';
import { useLocale } from '../../../../../infrastructure/hooks/useLocale';
import { emitTrackingEvent } from '../../../../../services/ioc';
import { TrackingEvents } from '../../../../../tracking/events';
import { emitGlobalError } from '../../../../../utils/eventEmitter';
import { usePaymentMethodsAvailability } from '../../../internals/AvailableIntegrations/usePaymentMethodsAvailability';
import { useController } from '../../../PaymentInstrumentSelect.controller';
import { AccordionDivider } from '../../AccordionDivider';
export var PaymentMethodsAccordionType;
(function (PaymentMethodsAccordionType) {
    PaymentMethodsAccordionType["ALL"] = "ALL";
    PaymentMethodsAccordionType["PAYMENT_METHOD"] = "PAYMENT_METHOD";
    PaymentMethodsAccordionType["SINGLE_USE_PAYMENT_METHOD"] = "SINGLE_USE_PAYMENT_METHOD";
})(PaymentMethodsAccordionType || (PaymentMethodsAccordionType = {}));
export const usePaymentMethodsAccordions = ({ paymentMethodsType }) => {
    const { publish } = useEvent();
    const { translate } = useLocale();
    const controller = useController((s) => s);
    const availability = usePaymentMethodsAvailability();
    const paymentInstrument = usePaymentInstrument();
    const mapAndFilterPaymentMethods = (paymentMethodsType) => {
        return Object.values(PaymentMethod)
            .map((paymentMethod) => {
            const { engine, singleUsePayment } = availability[paymentMethod];
            if (!engine)
                return null;
            const paymentMethodDetails = { paymentMethod, engine };
            const areSingleUsePaymentMethodsEnabled = Boolean(controller.showSingleUsePaymentMethods);
            const isSingleUsePaymentMethod = Boolean(singleUsePayment);
            if (paymentMethodsType === PaymentMethodsAccordionType.PAYMENT_METHOD) {
                return !singleUsePayment ? paymentMethodDetails : null;
            }
            if (paymentMethodsType === PaymentMethodsAccordionType.SINGLE_USE_PAYMENT_METHOD) {
                return isSingleUsePaymentMethod && areSingleUsePaymentMethodsEnabled ? paymentMethodDetails : null;
            }
            if (isSingleUsePaymentMethod) {
                return areSingleUsePaymentMethodsEnabled ? paymentMethodDetails : null;
            }
            return paymentMethodDetails;
        })
            .filter((item) => item !== null);
    };
    const getComponentFor = ({ paymentMethod, engine, paymentInstrument, }) => {
        const { Accordion, handler } = getAccordion(paymentMethod, engine);
        const onPressHandler = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            try {
                yield handler({
                    controller,
                    engine,
                    currentPaymentInstruments: paymentInstrument,
                    publish,
                    translate,
                    paymentInstrument,
                });
                emitTrackingEvent(TrackingEvents.PAYMENT_SELECTION, {
                    paymentMethod,
                    previousPaymentMethod: ((_a = paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.pendingPaymentInstrument) === null || _a === void 0 ? void 0 : _a.payment_method) ||
                        ((_b = paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.info) === null || _b === void 0 ? void 0 : _b.payment_method) ||
                        'none',
                    section: controller.section,
                });
            }
            catch (err) {
                const messageDefinition = getMessageForDeclineCode((_c = err === null || err === void 0 ? void 0 : err.payload) === null || _c === void 0 ? void 0 : _c.code);
                yield emitGlobalError(publish, Accordion.name, {
                    toaster: messageDefinition,
                    id: messageDefinition === null || messageDefinition === void 0 ? void 0 : messageDefinition.id,
                    translation: messageDefinition ? translate(messageDefinition) : '',
                });
            }
        });
        return (React.createElement(Touchable, { key: paymentMethod, role: "button", style: { width: '100%' }, onPress: onPressHandler, testID: paymentMethod },
            React.createElement(Accordion, null)));
    };
    const byPaymentMethodTypeOrder = (a, b, paymentMethodsType) => {
        const orderMap = {
            [PaymentMethodsAccordionType.ALL]: [PaymentMethod.IDEAL, PaymentMethod.CARD, PaymentMethod.PAYPAL],
            [PaymentMethodsAccordionType.PAYMENT_METHOD]: [PaymentMethod.CARD, PaymentMethod.PAYPAL],
            [PaymentMethodsAccordionType.SINGLE_USE_PAYMENT_METHOD]: [PaymentMethod.IDEAL],
        };
        const order = orderMap[paymentMethodsType];
        return order.indexOf(a.paymentMethod) - order.indexOf(b.paymentMethod);
    };
    return mapAndFilterPaymentMethods(paymentMethodsType)
        .sort((a, b) => byPaymentMethodTypeOrder(a, b, paymentMethodsType))
        .map((values) => getComponentFor({
        paymentMethod: values.paymentMethod,
        engine: values.engine,
        paymentInstrument,
    }))
        .flatMap((paymentMethodComponent, index) => [
        React.createElement(AccordionDivider, { error: controller.hasError, key: index }),
        paymentMethodComponent,
    ])
        .slice(1);
};
