import { useEvent } from '@lookiero/event';
import { useMemo, useState } from 'react';
import { CheckoutAPI } from '../../../infrastructure/CheckoutAPI';
import { graphqlClient } from '../../../infrastructure/graphql';
import { LegacyBoxCheckoutRepository as LegacyBoxCheckoutGraphqlRepository } from '../../../infrastructure/graphql/LegacyBoxCheckoutRepository';
import { useLocale } from '../../../infrastructure/hooks/useLocale';
import { LegacyBoxCheckoutAPI } from '../../../infrastructure/LegacyBoxCheckoutAPI';
import { PaymentRetryAPI } from '../../../infrastructure/PaymentRetryAPI';
import { chargeRepository, CheckoutRepository, pendingActionsRepository } from '../../../infrastructure/repositories';
import { useEngine } from '../../EngineProvider';
import { strategyBuilder } from './PaymentFlowStrategy';
import { CheckoutStrategy } from './strategies/CheckoutStrategy';
import { LegacyBoxCheckoutStrategy } from './strategies/LegacyBoxCheckoutStrategy';
import { PaymentRetryStrategy } from './strategies/PaymentRetryStrategy';
import { callback } from './utils/callback';
const initialState = {
    loading: false,
    challengeLoading: false,
    challengeVisible: false,
    challengeUrl: '',
    onChallengeLoad: callback(),
    onChallengeClose: callback(),
};
export function usePaymentFlow(token, withBalance = false, section) {
    const [state, setState] = useState(initialState);
    const { publish: emitGlobalError } = useEvent();
    const { translate } = useLocale();
    const { engineInstance } = useEngine();
    const buildStrategy = useMemo(() => {
        return strategyBuilder({
            initialState,
            setState,
            emitGlobalError,
            translate,
            stripe: engineInstance,
            section,
        });
    }, [emitGlobalError, translate, engineInstance, section]);
    const strategies = useMemo(() => {
        return {
            startCheckout: buildStrategy(CheckoutStrategy, {
                checkoutAPI: new CheckoutAPI(new CheckoutRepository(token), withBalance),
            }),
            startLegacyBoxCheckout: buildStrategy(LegacyBoxCheckoutStrategy, {
                legacyBoxCheckoutAPI: new LegacyBoxCheckoutAPI(new LegacyBoxCheckoutGraphqlRepository(graphqlClient(token)), chargeRepository),
            }),
            startPaymentRetry: buildStrategy(PaymentRetryStrategy, {
                paymentRetryAPI: new PaymentRetryAPI(pendingActionsRepository, chargeRepository),
            }),
        };
    }, [buildStrategy, token, withBalance]);
    return {
        state,
        strategies,
    };
}
