import { getCommonAssetsPath } from '../../services/ioc';
const getStripeLanguageFromLocale = (locale) => {
    if (locale === 'en-GB') {
        return locale;
    }
    return locale.split('-')[0];
};
const getElementsOptions = (locale) => ({
    locale: getStripeLanguageFromLocale(locale),
    fonts: [
        {
            family: 'AreaNormal-Semibold',
            src: `url(${getCommonAssetsPath()}/fonts/AreaNormal-Semibold.woff2)`,
            unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215',
        },
    ],
});
export { getElementsOptions, getStripeLanguageFromLocale };
