var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Locales } from '../../domain/models';
import { getHostLocale } from '../../services/ioc';
import { getCurrencyByLocale } from '../../services/localization';
export const useLocale = () => {
    const [locale, setLocale] = useState();
    const [currency, setCurrency] = useState();
    const { formatMessage, formatNumber } = useIntl();
    const translate = formatMessage;
    useEffect(() => {
        const getLocale = () => __awaiter(void 0, void 0, void 0, function* () {
            const hostLocale = yield getHostLocale();
            return Object.values(Locales).find((l) => l === hostLocale);
        });
        getLocale().then((locale) => {
            setLocale(locale);
            setCurrency(getCurrencyByLocale(locale));
        });
    }, []);
    return { translate, formatNumber, locale, currency };
};
