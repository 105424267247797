import { PaymentMethod, Engine } from '../../../domain/models';
import { cardAccordionHandler, idealAccordionHandler, idealDelayedHandler, paypalAccordionHandler, } from './accordions.handlers';
import { CardAccordion } from './CardAccordion';
import { IdealAccordion } from './IdealAccordion';
import { PaypalAccordion } from './PaypalAccordion';
/**
 * Accordion Maps:
 * Register here every available combination of PaymentMethod/Engine with it's
 * associated accordion and handler implementation.
 */
export const accordionMaps = [
    map(PaymentMethod.CARD, Engine.STRIPE, CardAccordion, cardAccordionHandler),
    map(PaymentMethod.CARD, Engine.STRIPE_UK, CardAccordion, cardAccordionHandler),
    map(PaymentMethod.PAYPAL, Engine.STRIPE, PaypalAccordion, paypalAccordionHandler),
    map(PaymentMethod.PAYPAL, Engine.STRIPE_UK, PaypalAccordion, paypalAccordionHandler),
    map(PaymentMethod.IDEAL, Engine.STRIPE, IdealAccordion, idealAccordionHandler, idealDelayedHandler),
];
export function map(paymentMethod, engine, component, handler, delayedHandler) {
    return {
        paymentMethod,
        engine,
        component: component,
        handler: handler,
        delayedHandler,
    };
}
export const getAccordionForPaymentInstrument = (paymentInstrument, engine) => {
    const accordion = findAccordionMap(paymentInstrument.payment_method, engine);
    return { Accordion: accordion.component, handler: accordion.handler };
};
export const getAccordion = (paymentMethod, engine) => {
    const accordion = findAccordionMap(paymentMethod, engine);
    return { Accordion: accordion.component, handler: accordion.handler };
};
export const getAccordionForPendingPaymentMethod = (paymentMethod, engine) => {
    const accordion = findAccordionMap(paymentMethod, engine);
    return { Accordion: accordion.component, handler: accordion.handler };
};
export const getDelayedHandlerForPendingPaymentMethod = (paymentMethod, engine) => findAccordionMap(paymentMethod, engine).delayedHandler;
export const findAccordionMap = (paymentMethod, engine) => accordionMaps.find((a) => a.paymentMethod === paymentMethod && a.engine === engine);
