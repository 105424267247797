var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AsyncStorage, LocalAdapter } from '@lookiero/data-sources';
import { PaymentMethod } from '../../../domain/models';
import { paymentInstrumentRepository } from '../../../infrastructure/repositories';
import { TrackingEvents } from '../../../tracking/events';
import { getSearchParam } from '../../../utils/parseSearchParams';
import { cleanUrl } from '../../../utils/url';
import { emitTrackingEvent, mustGetToken } from '../../ioc';
import { setupLinking } from '../../Linking';
import { getReturnedStatusEventBody, queue, store } from '../PaymentInstrumentFinalizer.controller';
const LAST_FINALIZED_PAYMENT_INSTRUMENT_KEY = 'payments__last_finalized_paypal';
export class ReturnedStatusHandler {
    constructor() {
        this.state = store.getState();
        this.storage = new AsyncStorage({ adapter: LocalAdapter });
        setupLinking(queue(this.processPayPalUrl.bind(this)));
    }
    processPayPalUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.finalizing)
                return;
            const setStateResult = (props) => {
                return this.state.addResult(Object.assign({ paymentMethod: PaymentMethod.PAYPAL }, props));
            };
            const paymentInstrumentId = getSearchParam(url, 'lk_payment_instrument_id');
            const redirectStatus = getSearchParam(url, 'redirect_status');
            const section = getSearchParam(url, 'lk_section');
            const pendingPaymentInstrumentRaw = decodeURIComponent(getSearchParam(url, 'pending_payment_instrument') || '');
            const pendingPaymentInstrument = pendingPaymentInstrumentRaw ? JSON.parse(pendingPaymentInstrumentRaw) : undefined;
            if (paymentInstrumentId == null)
                return;
            if ((yield this.getLastCharge(LAST_FINALIZED_PAYMENT_INSTRUMENT_KEY)) === paymentInstrumentId)
                return;
            yield this.setLastPaymentInstrumentFinalized(LAST_FINALIZED_PAYMENT_INSTRUMENT_KEY, paymentInstrumentId);
            emitTrackingEvent(TrackingEvents.PAYPAL_RETURN, {
                returnedStatus: getReturnedStatusEventBody(redirectStatus),
                section,
            });
            if (redirectStatus !== 'succeeded') {
                setStateResult({
                    success: false,
                    pendingPaymentInstrument,
                });
                return;
            }
            this.state.setFinalizing(true);
            try {
                yield this.finalizePaymentInstrument(paymentInstrumentId);
                emitTrackingEvent(TrackingEvents.PAYMENT_REGISTRATION, {
                    paymentMethod: PaymentMethod.PAYPAL,
                    output: 'success',
                    section,
                });
                setStateResult({
                    success: true,
                    pendingPaymentInstrument,
                });
            }
            catch (err) {
                const { code } = err;
                emitTrackingEvent(TrackingEvents.PAYMENT_REGISTRATION, {
                    paymentMethod: PaymentMethod.PAYPAL,
                    output: code != null && Number(code) < 500 ? 'rejected' : 'error',
                    section,
                });
                setStateResult({
                    success: false,
                    pendingPaymentInstrument,
                });
            }
            finally {
                this.state.setFinalizing(false);
                cleanUrl();
            }
        });
    }
    getLastCharge(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.storage).get(key);
        });
    }
    setLastPaymentInstrumentFinalized(key, paymentInstrumentId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield (yield this.storage).set(key, paymentInstrumentId);
        });
    }
    finalizePaymentInstrument(paymentInstrumentId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield paymentInstrumentRepository.finalizePayPalPaymentInstrument(paymentInstrumentId, yield mustGetToken());
        });
    }
}
