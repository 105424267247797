export var Engine;
(function (Engine) {
    Engine["STRIPE"] = "stripe";
    Engine["STRIPE_UK"] = "stripe_uk";
})(Engine || (Engine = {}));
export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["CARD"] = "card";
    PaymentMethod["PAYPAL"] = "paypal";
    PaymentMethod["IDEAL"] = "ideal";
})(PaymentMethod || (PaymentMethod = {}));
export var PaymentInstrumentBrand;
(function (PaymentInstrumentBrand) {
    PaymentInstrumentBrand["AMERICAN_EXPRESS"] = "amex";
    PaymentInstrumentBrand["CARD"] = "card";
    PaymentInstrumentBrand["MASTERCARD"] = "mastercard";
    PaymentInstrumentBrand["PAYPAL"] = "paypal";
    PaymentInstrumentBrand["VISA"] = "visa";
    PaymentInstrumentBrand["IDEAL"] = "ideal";
})(PaymentInstrumentBrand || (PaymentInstrumentBrand = {}));
export var PaymentInstrumentNetwork;
(function (PaymentInstrumentNetwork) {
    PaymentInstrumentNetwork["AMERICAN_EXPRESS"] = "amex";
    PaymentInstrumentNetwork["MASTERCARD"] = "mastercard";
    PaymentInstrumentNetwork["VISA"] = "visa";
    PaymentInstrumentNetwork["CARTES_BANCAIRES"] = "cartes_bancaires";
})(PaymentInstrumentNetwork || (PaymentInstrumentNetwork = {}));
export var Section;
(function (Section) {
    Section["BOX_OFON"] = "box-ofon";
    Section["BOX_CHECKOUT"] = "box-checkout";
    Section["BOX_PLACE_ORDER"] = "box-place-order";
    Section["DIRECT_BUY_CHECKOUT"] = "direct-buy-checkout";
    Section["MY_ACCOUNT"] = "my-account";
})(Section || (Section = {}));
export var ImageSizes;
(function (ImageSizes) {
    ImageSizes["S"] = "S";
    ImageSizes["M"] = "M";
    ImageSizes["L"] = "L";
})(ImageSizes || (ImageSizes = {}));
export var Countries;
(function (Countries) {
    Countries["AT"] = "AT";
    Countries["BE"] = "BE";
    Countries["CH"] = "CH";
    Countries["DE"] = "DE";
    Countries["ES"] = "ES";
    Countries["FR"] = "FR";
    Countries["GB"] = "GB";
    Countries["IT"] = "IT";
    Countries["LU"] = "LU";
    Countries["NL"] = "NL";
    Countries["PT"] = "PT";
    Countries["SE"] = "SE";
})(Countries || (Countries = {}));
export var Locales;
(function (Locales) {
    Locales["DE_AT"] = "de-AT";
    Locales["DE_CH"] = "de-CH";
    Locales["FR_CH"] = "fr-CH";
    Locales["IT_CH"] = "it-CH";
    Locales["FR_BE"] = "fr-BE";
    Locales["DE_DE"] = "de-DE";
    Locales["ES_ES"] = "es-ES";
    Locales["FR_FR"] = "fr-FR";
    Locales["IT_IT"] = "it-IT";
    Locales["PT_PT"] = "pt-PT";
    Locales["EN_GB"] = "en-GB";
    Locales["NL_NL"] = "nl-NL";
    Locales["LU_LU"] = "fr-LU";
    Locales["SV_SE"] = "sv-SE";
})(Locales || (Locales = {}));
export var Currencies;
(function (Currencies) {
    Currencies["EUR"] = "EUR";
    Currencies["CHF"] = "CHF";
    Currencies["GBP"] = "GBP";
    Currencies["SEK"] = "SEK";
})(Currencies || (Currencies = {}));
