var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getMessageForDeclineCode } from '../../../../domain/declineCodes';
import { PaymentMethod } from '../../../../domain/models';
import { PaymentFlowStrategy } from '../PaymentFlowStrategy';
import { ChargeStatus, } from '../PaymentFlowStrategy.definition';
import { StrategyType } from './StrategyType';
export class PaymentRetryStrategy extends PaymentFlowStrategy {
    start(val) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { value: payload, stripe, section } = val;
            const paymentInstrument = yield this.getPaymentInstrument();
            try {
                this.paymentStatusState.init(section, {
                    paymentMethod: paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.payment_method,
                    chargeId: payload.paymentId,
                });
                const retryPaymentResponse = yield this.executePaymentAttempt(payload.paymentId, paymentInstrument);
                if (retryPaymentResponse.status !== ChargeStatus.REQUIRES_ACTION) {
                    // In this case, the payment has been successfully completed if not,
                    // the data sources library returns an error caught by the catch block
                    this.successfullyCompletePaymentProcess(retryPaymentResponse, section);
                    return;
                }
                this.emit(ChargeStatus.REQUIRES_ACTION);
                if ((paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.payment_method) === PaymentMethod.IDEAL) {
                    yield this.processPaymentExternally(retryPaymentResponse, payload, paymentInstrument, section, stripe);
                }
                else {
                    yield this.processPaymentWithSCA({ executePaymentAttemptResult: retryPaymentResponse, section });
                }
            }
            catch (err) {
                this.resetState();
                const error = err;
                const messageDefinition = getMessageForDeclineCode(((_a = error === null || error === void 0 ? void 0 : error.payload) === null || _a === void 0 ? void 0 : _a.declineCode) || '');
                const metadata = (error === null || error === void 0 ? void 0 : error.payload) && {
                    id: error.payload.code,
                    translation: this.translate(messageDefinition),
                    toaster: messageDefinition,
                };
                void this.notifyUnexpectedError(error, { paymentId: payload.paymentId });
                this.paymentStatusState.finalize(section, {
                    success: false,
                    metadata,
                });
                this.emit(ChargeStatus.ERROR, Object.assign({ final: true }, metadata));
            }
        });
    }
    getType() {
        return StrategyType.PAYMENT_RETRY;
    }
    executePaymentAttempt(paymentId, paymentInstrument) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ui.loadingWhile(this.deps.paymentRetryAPI.retryPayment(paymentId, paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.payment_method, paymentInstrument === null || paymentInstrument === void 0 ? void 0 : paymentInstrument.engine));
            return {
                status: result.status,
                chargeId: result.chargeId,
                declineCode: result.status === ChargeStatus.REJECTED ? result.declineCode : undefined,
                challengeUrl: result.status === ChargeStatus.REQUIRES_ACTION ? result.metadata.redirectToUrl_url : undefined,
                challengeTimeout: undefined,
                clientSecret: result.metadata.clientSecret,
            };
        });
    }
    confirmCharge(chargeId) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = yield this.ui.challengeLoadingWhile(this.deps.paymentRetryAPI.confirmCharge(chargeId));
            return {
                status: result.status,
                declineCode: result.status === ChargeStatus.REJECTED ? result.declineCode : undefined,
            };
        });
    }
}
