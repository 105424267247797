import { useCallback, useMemo } from 'react';
import { useLocale } from '../../../infrastructure/hooks/useLocale';
import { store } from '../PaymentStatusManager.store';
export const usePaymentStatusManager = (section) => {
    const { translate } = useLocale();
    const payload = store(useCallback((state) => state.payments[section], [section]));
    const removeEntry = useCallback(() => store.getState().remove(section), [section]);
    const isLoading = Boolean(payload === null || payload === void 0 ? void 0 : payload.isLoading);
    const memoizedPayload = useMemo(() => {
        var _a, _b;
        if (!payload || !((_a = payload.metadata) === null || _a === void 0 ? void 0 : _a.toaster) || ((_b = payload.metadata) === null || _b === void 0 ? void 0 : _b.translation))
            return payload;
        return Object.assign(Object.assign({}, payload), { metadata: Object.assign(Object.assign({}, payload.metadata), { translation: translate(payload.metadata.toaster) }) });
    }, [payload, translate]);
    const consumePayload = useCallback((callback) => {
        if (isLoading || !memoizedPayload)
            return;
        callback(memoizedPayload);
        removeEntry();
    }, [isLoading, removeEntry, memoizedPayload]);
    return useMemo(() => ({ isLoading, consumePayload }), [isLoading, consumePayload]);
};
