import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { usePaymentInstrument } from '../../hooks/usePaymentInstrument';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { CardUpdater } from '../CardUpdater';
import { EngineContext, useEngine } from '../EngineProvider';
import { TEXT as TEXT_IDEAL } from '../PaymentInstrumentSelect/accordions/IdealAccordion/ideal.definition';
import { PaymentInstrumentSelectControllerContext, useController, } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { TEXT } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.definition';
import { PaymentPanel } from '../PaymentPanel';
const CardUpdaterPanel = () => {
    const storeInstance = useContext(PaymentInstrumentSelectControllerContext);
    const engineInstance = useEngine();
    const [panelVisibility, hideCardUpdaterPanel] = useController((s) => [s.panelVisibility, s.hideCardUpdaterPanel]);
    const { translate } = useLocale();
    const paymentInstrument = usePaymentInstrument();
    const { visible, disableAnimation } = panelVisibility.cardUpdater;
    const title = paymentInstrument.pendingPaymentInstrument && !paymentInstrument.info
        ? TEXT_IDEAL.TITLE_IDEAL
        : TEXT.PANEL_CARD_TITLE;
    return (React.createElement(PaymentPanel, { id: "CardUpdaterPanel", title: translate(title), isVisible: visible, disableAnimation: disableAnimation, onClose: hideCardUpdaterPanel },
        React.createElement(PaymentInstrumentSelectControllerContext.Provider, { value: storeInstance },
            React.createElement(EngineContext.Provider, { value: engineInstance }, visible && React.createElement(CardUpdater, null)))));
};
CardUpdaterPanel.displayName = 'CardUpdaterPanel';
CardUpdaterPanel.propTypes = {
    onClose: PropTypes.func,
};
export { CardUpdaterPanel };
