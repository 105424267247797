import { PaymentInstrumentBrand, PaymentInstrumentNetwork } from '../../../domain/models';
import { LogoName } from '../types/LogoTypes';
/**
 * Logo Maps:
 * Register here every PaymentInstrumentBrand and PaymentInstrumentNetwork with their respective logo image.
 * If a network is specified, it has priority over the brand.
 */
const logoMaps = [
    mapLogo(LogoName.AMERICAN_EXPRESS, PaymentInstrumentBrand.AMERICAN_EXPRESS, PaymentInstrumentNetwork.AMERICAN_EXPRESS),
    mapLogo(LogoName.CARD, PaymentInstrumentBrand.CARD),
    mapLogo(LogoName.MASTERCARD, PaymentInstrumentBrand.MASTERCARD, PaymentInstrumentNetwork.MASTERCARD),
    mapLogo(LogoName.PAYPAL, PaymentInstrumentBrand.PAYPAL),
    mapLogo(LogoName.VISA, PaymentInstrumentBrand.VISA, PaymentInstrumentNetwork.VISA),
    mapLogo(LogoName.CARTES_BANCAIRES, PaymentInstrumentBrand.CARD, PaymentInstrumentNetwork.CARTES_BANCAIRES),
    mapLogo(LogoName.IDEAL, PaymentInstrumentBrand.IDEAL),
];
function mapLogo(logoName, paymentInstrumentBrand, paymentInstrumentNetwork) {
    return {
        logoName,
        paymentInstrumentBrand,
        paymentInstrumentNetwork,
    };
}
export const findLogo = (paymentInstrumentBrand, paymentInstrumentNetworks, paymentInstrumentPreferredNetwork) => {
    if (paymentInstrumentPreferredNetwork) {
        const logo = logoMaps.find((logo) => logo.paymentInstrumentNetwork === paymentInstrumentPreferredNetwork);
        if (logo)
            return logo.logoName;
    }
    if (paymentInstrumentNetworks) {
        const logo = logoMaps.find((logo) => paymentInstrumentNetworks.find((network) => logo.paymentInstrumentNetwork === network));
        if (logo)
            return logo.logoName;
    }
    const logo = logoMaps.find((logo) => logo.paymentInstrumentBrand === paymentInstrumentBrand);
    if (logo)
        return logo.logoName;
    return findFallbackLogo(paymentInstrumentBrand);
};
const findFallbackLogo = (paymentInstrumentBrand) => {
    switch (paymentInstrumentBrand) {
        case PaymentInstrumentBrand.PAYPAL:
            return LogoName.PAYPAL;
        case PaymentInstrumentBrand.IDEAL:
            return LogoName.IDEAL;
        default:
            return LogoName.CARD;
    }
};
