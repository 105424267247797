import { useCallback, useMemo } from 'react';
import { PaymentMethod } from '../../../../domain/models';
import { useController } from '../../PaymentInstrumentSelect.controller';
export const usePaymentMethodsAvailability = () => {
    const [hidePaymentMethods, availableIntegrations] = useController((s) => [
        s.hidePaymentMethods,
        s.availableIntegrations,
    ]);
    const getPaymentMethodData = useCallback((paymentMethod) => {
        if (hidePaymentMethods === null || hidePaymentMethods === void 0 ? void 0 : hidePaymentMethods.includes(paymentMethod))
            return { engine: null };
        const paymentIntegration = availableIntegrations.integrations.find((data) => {
            const hasPaymentMethod = (availableMethod) => availableMethod === paymentMethod;
            return data.availableMethods.some(hasPaymentMethod) || data.singleUsePaymentMethods.some(hasPaymentMethod);
        });
        if (paymentIntegration) {
            const { engine, singleUsePaymentMethods } = paymentIntegration;
            return { engine, singleUsePayment: singleUsePaymentMethods === null || singleUsePaymentMethods === void 0 ? void 0 : singleUsePaymentMethods.includes(paymentMethod) };
        }
        return { engine: null };
    }, [hidePaymentMethods, availableIntegrations]);
    return useMemo(() => ({
        [PaymentMethod.CARD]: getPaymentMethodData(PaymentMethod.CARD),
        [PaymentMethod.IDEAL]: getPaymentMethodData(PaymentMethod.IDEAL),
        [PaymentMethod.PAYPAL]: getPaymentMethodData(PaymentMethod.PAYPAL),
    }), [getPaymentMethodData]);
};
