var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { getGraphqlUri } from '../../services/ioc';
import { getHeaders } from '../headers';
const inMemoryCache = new InMemoryCache({ addTypename: false });
let GraphqlClientStatic;
// We can't create the ApolloClient when the module is loaded because React Native changes the fetch function asynchronously
// and Apollo uses fetch inside the Client creation.
export const graphqlClient = (token) => {
    if (GraphqlClientStatic !== undefined) {
        return GraphqlClientStatic;
    }
    GraphqlClientStatic = new ApolloClient({
        cache: inMemoryCache,
        version: '0.0.1',
        uri: getGraphqlUri(),
        fetch,
        request: (operation) => __awaiter(void 0, void 0, void 0, function* () {
            operation.setContext({
                headers: Object.assign({}, getHeaders(token)),
            });
        }),
    });
    return GraphqlClientStatic;
};
