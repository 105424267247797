var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ALIGN, Button, DISPLAY, FLEX_DIRECTION, Modal, SPACE, View } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import React, { useEffect, useRef, useState } from 'react';
import { getMessageForDeclineCode } from '../../domain/declineCodes';
import { PaymentMethod, Section } from '../../domain/models';
import { usePaymentInstrumentUpdateInternalEvents, } from '../../hooks/usePaymentInstrumentUpdateInternalEvents';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { mustGetToken } from '../../services/ioc';
import { usePaymentStatusManagerEvents } from '../../services/PaymentStatusManager';
import { emitPaymentError, emitPaymentSuccess } from '../../utils/eventEmitter';
import { PaymentFlow } from '../PaymentFlow';
import { PaymentInstrumentSelect } from '../PaymentInstrumentSelect';
import { Portal } from '../Portal';
import { getPendingPayment } from './RetryPaymentModal.controller';
import { TEXT } from './RetryPaymentModal.definition';
export const RetryPaymentModal = ({ isVisible, userInformation, beforeRedirect, showSingleUsePaymentMethods, }) => {
    const { currency, locale, translate, formatNumber } = useLocale();
    const { publish } = useEvent();
    const paymentInstrumentSelectRef = useRef(null);
    const paymentFlowRef = useRef(null);
    const [busy, setBusy] = useState(false);
    const [visible, setVisible] = useState(false);
    const [pendingPayment, setPendingPayment] = useState(null);
    const [token, setToken] = useState(null);
    const onPaymentSuccess = (payload) => {
        var _a, _b;
        const message = {
            id: ((_a = payload.metadata) === null || _a === void 0 ? void 0 : _a.id) || TEXT.NOTIFICATION_PAYMENT_SUCCESS.id,
            translation: ((_b = payload.metadata) === null || _b === void 0 ? void 0 : _b.translation) || translate(TEXT.NOTIFICATION_PAYMENT_SUCCESS),
        };
        setTimeout(() => emitPaymentSuccess(publish, RetryPaymentModal.name, message, payload.paymentMethod, payload), 200);
        getPendingPayment().then(setPendingPayment);
    };
    const onPaymentError = (payload) => {
        var _a, _b, _c;
        if (((_a = payload.metadata) === null || _a === void 0 ? void 0 : _a.id) && ((_b = payload.metadata) === null || _b === void 0 ? void 0 : _b.translation)) {
            const message = {
                id: payload.metadata.id,
                translation: (_c = payload.metadata) === null || _c === void 0 ? void 0 : _c.translation,
            };
            setTimeout(() => emitPaymentError(publish, RetryPaymentModal.name, message, payload.paymentMethod, payload), 200);
        }
    };
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            setToken(yield mustGetToken());
            setPendingPayment(yield getPendingPayment());
        });
        void fetchData();
    }, []);
    useEffect(() => {
        setVisible(isVisible && !busy && !!pendingPayment);
    }, [busy, pendingPayment, isVisible]);
    usePaymentStatusManagerEvents({
        section: Section.BOX_OFON,
        onSuccess: onPaymentSuccess,
        onError: onPaymentError,
    });
    const isThePaymentMethodCard = (eventPayload) => eventPayload.paymentMethod === PaymentMethod.CARD;
    const isThePaymentMethodIdeal = (eventPayload) => eventPayload.paymentMethod === PaymentMethod.IDEAL;
    usePaymentInstrumentUpdateInternalEvents({
        onSuccess: (payload) => __awaiter(void 0, void 0, void 0, function* () {
            if (payload.section === Section.BOX_OFON &&
                (isThePaymentMethodCard(payload) || isThePaymentMethodIdeal(payload))) {
                yield handleOnPress();
            }
        }),
    });
    const onCloseModal = () => {
        setVisible(false);
    };
    const handleOnPress = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        try {
            setBusy(true);
            if (!(pendingPayment === null || pendingPayment === void 0 ? void 0 : pendingPayment.id)) {
                setPendingPayment(yield getPendingPayment());
                return;
            }
            yield ((_a = paymentFlowRef.current) === null || _a === void 0 ? void 0 : _a.startPaymentRetry({
                paymentId: pendingPayment === null || pendingPayment === void 0 ? void 0 : pendingPayment.id,
                userInformation,
                returnUrl: yield beforeRedirect(),
            }));
        }
        catch (error) {
            const message = getMessageForDeclineCode((_b = error.payload) === null || _b === void 0 ? void 0 : _b.declineCode);
            if (message) {
                emitPaymentError(publish, RetryPaymentModal.name, Object.assign(Object.assign({}, message), { translation: translate(message) }));
            }
        }
        finally {
            setBusy(false);
        }
    });
    const description = (pendingPayment === null || pendingPayment === void 0 ? void 0 : pendingPayment.error.code) &&
        translate(getMessageForDeclineCode(pendingPayment.error.code), {
            amount: formatNumber(pendingPayment.amount / 100, { style: 'currency', currency }),
            date: pendingPayment.date.toLocaleDateString(locale),
            order: pendingPayment.order,
        });
    if (!token || !pendingPayment)
        return React.createElement(React.Fragment, null);
    return (React.createElement(Portal, null,
        React.createElement(Modal, { scrollable: true, scrollToEnd: visible, isVisible: visible, description: description, title: translate(description ? TEXT.MODAL_TITLE_ERROR : TEXT.MODAL_TITLE), onClose: busy ? undefined : onCloseModal, nativeID: "ofon-modal", testID: "payment-modal-retry" },
            React.createElement(View, { display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.START, wide: true },
                React.createElement(PaymentInstrumentSelect, { section: Section.BOX_OFON, hasError: false, beforeRedirect: beforeRedirect, ref: paymentInstrumentSelectRef, userInformation: userInformation, showSingleUsePaymentMethods: showSingleUsePaymentMethods }),
                React.createElement(PaymentFlow, { ref: paymentFlowRef, token: token, section: Section.BOX_OFON }),
                React.createElement(Button, { busy: busy, marginTop: SPACE.SPACE_6, onPress: handleOnPress }, translate(busy ? TEXT.CTA_BUSY : TEXT.CTA_RETRY))))));
};
