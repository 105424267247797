var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AsyncStorage, LocalAdapter } from '@lookiero/data-sources';
import { StrategyType } from '../../../components';
import { getEngineFromStorage } from '../../../components/EngineProvider';
import { PaymentMethod } from '../../../domain/models';
import { getSearchParam } from '../../../utils/parseSearchParams';
import { cleanUrl } from '../../../utils/url';
import { setupLinking } from '../../Linking';
import { confirmPaymentStatus } from '../PaymentStatusConfirm';
import { refreshCharge } from '../PaymentStatusRefresh';
const LAST_REFRESHED_PAYMENT_METHOD_KEY = 'payments__last_refreshed_payment_method';
export class ReturnedStatusHandler {
    constructor() {
        this.storage = new AsyncStorage({ adapter: LocalAdapter });
        setupLinking(this.processUrl.bind(this));
    }
    processUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const paymentMethod = getSearchParam(url, 'lk_payment_method');
            if (!paymentMethod)
                return;
            if (paymentMethod === PaymentMethod.IDEAL)
                yield this.processIdeal(url);
        });
    }
    processIdeal(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const section = getSearchParam(url, 'lk_section');
            const chargeId = getSearchParam(url, 'lk_charge_id');
            const paymentStrategy = getSearchParam(url, 'lk_payment_strategy') || StrategyType.LEGACY_BOX;
            const paymentIntentClientSecretId = getSearchParam(url, 'payment_intent_client_secret');
            const redirectStatus = getSearchParam(url, 'redirect_status');
            if (chargeId == null || section == null || paymentIntentClientSecretId == null)
                return;
            if ((yield this.getLastCharge(LAST_REFRESHED_PAYMENT_METHOD_KEY)) === chargeId)
                return;
            yield this.setLastPaymentInstrumentFinalized(LAST_REFRESHED_PAYMENT_METHOD_KEY, chargeId);
            try {
                const engine = yield getEngineFromStorage();
                const paymentResult = redirectStatus ? redirectStatus : undefined;
                const params = {
                    engine,
                    chargeId,
                    section,
                    paymentMethod: PaymentMethod.IDEAL,
                    paymentResult,
                };
                if (paymentStrategy === StrategyType.CHECKOUT) {
                    yield confirmPaymentStatus(params);
                }
                else {
                    yield refreshCharge(params);
                }
            }
            finally {
                cleanUrl();
            }
        });
    }
    getLastCharge(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return (yield this.storage).get(key);
        });
    }
    setLastPaymentInstrumentFinalized(key, paymentInstrumentId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield (yield this.storage).set(key, paymentInstrumentId);
        });
    }
}
