var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ALIGN, Box, COLOR, DISPLAY, FLEX_DIRECTION, Icon, Image, IMAGE_RESIZE_MODES, Layout, SPACE, Text, useDevice, } from '@lookiero/aurora';
import React, { useContext, useMemo } from 'react';
import { ImageSizes } from '../../domain/models';
import { usePaymentInstrument } from '../../hooks/usePaymentInstrument';
import { getCDNImages } from '../../infrastructure/getCDNImages';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { TEXT as TEXT_IDEAL } from '../PaymentInstrumentSelect/accordions/IdealAccordion/ideal.definition';
import { PaymentMethods } from '../PaymentInstrumentSelect/internals/PaymentMethods';
import { PaymentInstrumentSelectControllerContext, useController, } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { PaymentPanel } from '../PaymentPanel';
import { TEXT } from './PaymentMethodsPanel.definition';
export const PaymentMethodsPanel = () => {
    const { screen } = useDevice();
    const storeInstance = useContext(PaymentInstrumentSelectControllerContext);
    const controller = useController((s) => s);
    const { translate } = useLocale();
    const paymentInstrument = usePaymentInstrument();
    const { src, srcSet } = getCDNImages('ideal', ImageSizes.M);
    const handleOnClose = () => __awaiter(void 0, void 0, void 0, function* () {
        yield paymentInstrument.updatePendingPaymentInstrument(null);
        controller.setShowPaymentMethodsPanel(false);
    });
    const { visible, disableAnimation } = controller.panelVisibility.paymentMethodsSelect;
    const flexStyles = useMemo(() => ({
        responsive: {
            flex: screen.L ? undefined : 1,
        },
        borderRadius: {
            borderRadius: screen.L ? 8 : undefined,
        },
    }), [screen.L]);
    return (React.createElement(PaymentPanel, { id: "PaymentInstrumentSelectPanel", title: translate(TEXT_IDEAL.TITLE_IDEAL), isVisible: visible, disableAnimation: disableAnimation, onClose: handleOnClose },
        React.createElement(Layout, { display: DISPLAY.FLEX, inline: false, fullWidth: !screen.L, alignItems: ALIGN.CENTER, alignContent: ALIGN.CENTER, justifyContent: ALIGN.CENTER, testID: "Layout", style: flexStyles.responsive },
            React.createElement(Box, { size: { M: '2/3', L: '1/3' }, flexDirection: FLEX_DIRECTION.COLUMN, paddingHorizontal: SPACE.SPACE_6, paddingBottom: SPACE.SPACE_12 },
                React.createElement(Image, { autoSize: true, marginBottom: SPACE.SPACE_3, resizeMode: IMAGE_RESIZE_MODES.center, src: src, srcSet: srcSet, style: { height: 48, width: 48 } }),
                React.createElement(Text, { heading: true, level: 3, marginBottom: SPACE.SPACE_2 }, translate(TEXT.ALTERNATIVE_METHOD_TITLE)),
                React.createElement(Text, { detail: true, level: 2, marginBottom: SPACE.SPACE_6 }, translate(TEXT.ALTERNATIVE_METHOD_BODY)),
                React.createElement(PaymentInstrumentSelectControllerContext.Provider, { value: storeInstance },
                    React.createElement(PaymentMethods, null))),
            React.createElement(Box, { size: { M: '2/3', L: '1/3' }, alignItems: ALIGN.START, justifyContent: ALIGN.START, backgroundColor: COLOR.BG_PRIMARY_LIGHT, paddingVertical: SPACE.SPACE_8, paddingHorizontal: SPACE.SPACE_6, style: [flexStyles.responsive, flexStyles.borderRadius] },
                React.createElement(Text, { detailBold: true, level: 1, marginBottom: SPACE.SPACE_6 }, translate(TEXT.ALTERNATIVE_METHOD_MORE_INFO_TITLE)),
                React.createElement(Box, { flexDirection: FLEX_DIRECTION.ROW, alignItems: ALIGN.CENTER, marginBottom: SPACE.SPACE_3 },
                    React.createElement(Icon, { name: 'package', marginRight: SPACE.SPACE_2 }),
                    React.createElement(Text, { detail: true, level: 2 }, translate(TEXT.ALTERNATIVE_METHOD_MORE_INFO_BULLET1))),
                React.createElement(Box, { flexDirection: FLEX_DIRECTION.ROW, alignItems: ALIGN.CENTER, marginBottom: SPACE.SPACE_6 },
                    React.createElement(Icon, { name: 'returns', marginRight: SPACE.SPACE_2 }),
                    React.createElement(Text, { detail: true, level: 2 }, translate(TEXT.ALTERNATIVE_METHOD_MORE_INFO_BULLET2))),
                React.createElement(Text, { detail: true, level: 2, marginBottom: SPACE.SPACE_6 }, translate(TEXT.ALTERNATIVE_METHOD_MORE_INFO_END))))));
};
