import create from 'zustand';
export const store = create((set, get) => ({
    finalizing: false,
    setFinalizing: (value) => set((s) => (Object.assign(Object.assign({}, s), { finalizing: value }))),
    resultQueue: [],
    addResult: (r) => set((s) => (Object.assign(Object.assign({}, s), { resultQueue: [...s.resultQueue, r] }))),
    consumeResult: (cb) => {
        const resultQueue = get().resultQueue;
        if (resultQueue.length === 0)
            return;
        resultQueue.forEach((r) => cb(r));
        set((s) => (Object.assign(Object.assign({}, s), { resultQueue: [] })));
    },
}));
export const getReturnedStatusEventBody = (redirectStatus) => {
    if (redirectStatus === 'succeeded')
        return 'success';
    if (redirectStatus === 'failed')
        return 'rejected';
    if (redirectStatus === 'pending')
        return 'cancelled';
    return 'error';
};
export const queue = (cb) => {
    let previousPromise = Promise.resolve();
    return (url) => (previousPromise = previousPromise.then(() => cb(url)).catch(() => Promise.resolve()));
};
