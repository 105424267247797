var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Modal, useDevice, View } from '@lookiero/aurora';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { useAvoidKeyboard } from '../../services/ioc';
import { isWeb } from '../../utils/platform';
import { EngineProvider, useSetupEngine } from '../EngineProvider';
import { useAvailableIntegrations } from '../PaymentInstrumentSelect/internals/AvailableIntegrations';
import { Portal } from '../Portal';
import { WebView } from '../WebView';
import { ChallengeResult } from './internals/PaymentFlowStrategy.definition';
import { usePaymentFlow } from './internals/usePaymentFlow';
export const PaymentFlow = forwardRef((p, ref) => {
    var _a;
    const availableIntegrations = useAvailableIntegrations(p.section);
    const engine = (_a = availableIntegrations.integrations[0]) === null || _a === void 0 ? void 0 : _a.engine;
    const engineInstance = useSetupEngine(engine);
    const { locale } = useLocale();
    if (!engine || !locale || !engineInstance || availableIntegrations.loading)
        return null;
    return (React.createElement(EngineProvider, { engine: engine, locale: locale, engineInstance: engineInstance },
        React.createElement(PaymentFlowInner, Object.assign({ ref: ref }, p))));
});
export const PaymentFlowInner = forwardRef((_a, ref) => {
    var p = __rest(_a, []);
    const { setAvoidKeyboard } = useAvoidKeyboard();
    const { state, strategies } = usePaymentFlow(p.token, p.withBalance, p.section);
    useImperativeHandle(ref, () => strategies, [strategies]);
    const { translate: defaultTranslate } = useLocale();
    const translate = p.translate || defaultTranslate;
    useEffect(() => {
        setAvoidKeyboard(!state.challengeVisible);
        return () => {
            setAvoidKeyboard(true);
        };
    }, [setAvoidKeyboard, state.challengeVisible]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ChallengeModal, { url: state.challengeUrl, visible: state.challengeVisible, onLoad: state.onChallengeLoad.call, onClose: () => state.onChallengeClose.call(ChallengeResult.CANCELED), onFinish: () => state.onChallengeClose.call(ChallengeResult.FINISHED) }),
        React.createElement(PaymentInProgressModal, { translate: translate, enabled: state.loading || state.challengeLoading })));
});
PaymentFlow.displayName = 'PaymentFlow';
const PaymentInProgressModal = (p) => (React.createElement(Portal, null,
    React.createElement(Modal, { isVisible: p.enabled, title: p.translate({ id: 'payment.processing.title_bank_connecting' }), description: p.translate({ id: 'payment.processing.paragraph_card_check' }) })));
const ChallengeModal = (p) => {
    return (React.createElement(WebViewModal, { url: p.url, visible: p.visible, onLoad: p.onLoad, onClose: p.onClose, onMessage: (msg) => {
            const m = msg;
            if (!m.data)
                return;
            if (!m.data.payment_intent_authorization_finished)
                return;
            if (!m.data.payment_intent_authorization_finished.response)
                return;
            p.onFinish();
        } }));
};
const WebViewModal = (p) => {
    const { screen } = useDevice();
    const webViewPaddingBottom = screen.S && isWeb ? 0 : undefined;
    return (React.createElement(Portal, null,
        React.createElement(Modal
        /*
         * Ugly solution to solve the problem that has been detected in android
         * when displaying panels. If a modal is rendered and has the prop
         * fullheight set to true the content of the panel is shifted down a few pixels.
         * Probably this problem is related with @gorhom/portal library.
         */
        , { 
            /*
             * Ugly solution to solve the problem that has been detected in android
             * when displaying panels. If a modal is rendered and has the prop
             * fullheight set to true the content of the panel is shifted down a few pixels.
             * Probably this problem is related with @gorhom/portal library.
             */
            fullHeight: p.visible, fullWidth: true, scrollable: false, isVisible: p.visible, onClose: p.onClose, size: { M: '2/3', L: '2/3' }, contentStyle: { flex: 1, width: '100%', paddingHorizontal: 0, paddingBottom: webViewPaddingBottom } },
            React.createElement(View, { style: { flex: 1 } },
                React.createElement(WebView, { url: p.url, onLoad: p.onLoad, onMessage: p.onMessage, style: { height: '100%' } })))));
};
