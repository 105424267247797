import { COLOR, SPACE, Text, View } from '@lookiero/aurora';
import React from 'react';
import { useLocale } from '../../../../infrastructure/hooks/useLocale';
import { PaymentMethodsAccordionType, usePaymentMethodsAccordions, } from '../../accordions/hooks/usePaymentMethodsAccordions/usePaymentMethodsAccordions';
import { useController } from '../../PaymentInstrumentSelect.controller';
import { TEXT } from '../../PaymentInstrumentSelect.definition';
import { ErrorBlock } from '../ErrorBlock';
import { style } from './SingleUsePaymentMethods.styles';
const SingleUsePaymentMethods = () => {
    const { translate } = useLocale();
    const controller = useController((s) => s);
    const paymentMethodsAccordions = usePaymentMethodsAccordions({
        paymentMethodsType: PaymentMethodsAccordionType.SINGLE_USE_PAYMENT_METHOD,
    });
    if (!paymentMethodsAccordions.length)
        return React.createElement(React.Fragment, null);
    return (React.createElement(React.Fragment, null,
        React.createElement(Text, { marginTop: SPACE.SPACE_3, detail: true, level: 1, color: COLOR.TEXT_MEDIUM }, translate(TEXT.SINGLE_PAYMENT_METHODS_TITLE)),
        React.createElement(View, { marginTop: SPACE.SPACE_3, style: [style.container, controller.hasError && style.containerError] }, paymentMethodsAccordions),
        controller.hasError && React.createElement(ErrorBlock, null)));
};
export { SingleUsePaymentMethods };
