var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CheckoutStatus } from '../../components';
import { getMessageForDeclineCode } from '../../domain/declineCodes';
import { CheckoutAPI } from '../../infrastructure/CheckoutAPI';
import { CheckoutRepository } from '../../infrastructure/repositories';
import { mustGetToken } from '../ioc';
import { store } from '../PaymentStatusManager';
export const confirmPaymentStatus = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const { chargeId, paymentResult, section, engine, paymentMethod } = payload;
    const checkoutApi = new CheckoutAPI(new CheckoutRepository(yield mustGetToken()), false);
    const state = store.getState();
    try {
        state.init(section, { paymentMethod, chargeId });
        if (paymentResult !== 'succeeded') {
            throw new Error();
        }
        const confirmResult = yield checkoutApi.confirmCharge({ chargeId, paymentMethod, engine });
        const success = ((_a = confirmResult.checkout) === null || _a === void 0 ? void 0 : _a.status) === CheckoutStatus.FULFILLED;
        state.finalize(section, { success });
    }
    catch (error) {
        const errorCode = ((_b = error === null || error === void 0 ? void 0 : error.payload) === null || _b === void 0 ? void 0 : _b.code) || '';
        const messageDefinition = getMessageForDeclineCode(errorCode);
        state.finalize(section, {
            success: false,
            metadata: {
                id: messageDefinition.id,
                toaster: messageDefinition,
            },
        });
    }
});
