export const parseSearchParams = (url) => {
    if (url.indexOf('?') === -1)
        return {};
    return url
        .split('?')[1]
        .split('#')[0]
        .split('&')
        .map((kv) => kv.split('='))
        .reduce((result, [key, value]) => (Object.assign(Object.assign({}, result), { [key]: [...(result[key] || []), value] })), {});
};
export const getSearchParam = (url, key) => {
    const values = parseSearchParams(url)[key] || [];
    return values[values.length - 1] || null;
};
