var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isWeb } from './platform';
export const cleanUrl = () => {
    if (!isWeb)
        return;
    const cleanUrl = location.href.split('?')[0];
    history.replaceState({}, document.title, cleanUrl);
};
export const getReturnUrl = ({ controller, queryParams }) => __awaiter(void 0, void 0, void 0, function* () {
    if (controller.beforeRedirectCallback == null) {
        throw new Error('PaymentInstrumentSelect property beforeRedirect was not defined');
    }
    const returnUrl = yield controller.beforeRedirectCallback();
    if (returnUrl == null) {
        throw new Error('PaymentInstrumentSelect beforeRedirect implementation returned null');
    }
    const queryString = new URLSearchParams(queryParams).toString();
    const separator = returnUrl.includes('?') ? '&' : '?';
    return queryString ? `${returnUrl}${separator}${queryString}` : returnUrl;
});
