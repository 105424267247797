import { 
// Helpers
ALIGN, 
// Components
Text, View, } from '@lookiero/aurora';
import React from 'react';
export const Copyright = () => (React.createElement(React.Fragment, null,
    Array.from({ length: 15 }).map((a, i) => (React.createElement(View, { key: i, testID: "fb-space" },
        React.createElement(Text, null, "\u200C")))),
    React.createElement(Text, { align: ALIGN.CENTER, as: "p", detail: true, style: { paddingBottom: 8 } }, `© ${new Date().getFullYear()} LOOKIERO`)));
