import { DISPLAY, FLEX_DIRECTION, SIZE, SPACE, Text, View } from '@lookiero/aurora';
import React from 'react';
import { PaymentInstrumentBrand } from '../../../../domain/models';
import { useLocale } from '../../../../infrastructure/hooks/useLocale';
import { PaymentAccordion } from '../../../PaymentAccordion';
import { TEXT } from '../../PaymentInstrumentSelect.definition';
export const IdealAccordion = () => {
    const { translate } = useLocale();
    return (React.createElement(PaymentAccordion, { metadata: { brand: PaymentInstrumentBrand.IDEAL }, testID: 'IdealAccordion' },
        React.createElement(View, { display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, marginRight: SPACE.SPACE_4, flex: SIZE.XS },
            React.createElement(Text, { action: true, ellipsizeMode: true, level: 3 }, translate(TEXT.IDEAL)))));
};
