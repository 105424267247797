import { useEffect } from 'react';
import { usePaymentStatusManager } from './usePaymentStatusManager';
// TODO actualmente utilizamos el section id para saber si tenemos que realizar alguna operación post-refresh
// pero deberíamos intentar utilizar otro identificador más robusto e inequívoco del cual el host sea conocedor,
// como un order id, transaction id, charge id ...
export const usePaymentStatusManagerEvents = ({ section, onSuccess = () => void 0, onError = () => void 0, }) => {
    const paymentStatus = usePaymentStatusManager(section);
    useEffect(() => {
        const { isLoading, consumePayload } = paymentStatus;
        if (isLoading)
            return;
        consumePayload((payload) => {
            if (payload.success) {
                onSuccess(payload);
            }
            else {
                onError(payload);
            }
        });
    }, [onSuccess, onError, paymentStatus, section]);
};
