var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '@lookiero/data-sources';
import { mustGetToken } from '../../services/ioc';
import { getHeaders } from '../headers';
export class PaymentInstrumentRepository {
    changePaymentInstrumentByPaymentMethodId(engine, paymentMethod, paymentMethodId, token) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/payments/v1/payment-instrument/',
                method: 'POST',
                headers: getHeaders(token),
                paymentEngine: engine,
                metadata: { paymentMethodId, paymentMethod },
            });
        });
    }
    getAvailableIntegrations(section, token) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/payments/v1/available-integrations',
                method: 'POST',
                headers: getHeaders(token),
                section,
            });
        });
    }
    getPaymentInstrumentInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield mustGetToken();
            return yield request({
                endpoint: '/web/api/payment-instruments/v2',
                method: 'GET',
                headers: getHeaders(token),
            });
        });
    }
    createPayPalPaymentInstrument(returnUrl, token, engine) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/payments/v1/paypal/payment-instrument/create',
                method: 'POST',
                headers: getHeaders(token),
                returnUrl,
                paymentEngine: engine,
            });
        });
    }
    finalizePayPalPaymentInstrument(paymentInstrumentId, token) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/payments/v1/paypal/payment-instrument/finalize',
                method: 'POST',
                headers: getHeaders(token),
                paymentInstrumentId,
            });
        });
    }
}
export const paymentInstrumentRepository = new PaymentInstrumentRepository();
