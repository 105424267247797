import { View, Skeleton as SkeletonAurora, ALIGN, DISPLAY, FLEX_DIRECTION, SPACE } from '@lookiero/aurora';
import React from 'react';
import { style } from './Skeleton.styles';
export const Skeleton = () => {
    return (React.createElement(View, { alignItems: ALIGN.STRETCH, display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, paddingVertical: SPACE.SPACE_4, testID: 'skeleton', wide: true },
        React.createElement(View, { style: style.header, flexDirection: FLEX_DIRECTION.ROW, justifyContent: ALIGN.BETWEEN, paddingBottom: SPACE.SPACE_4 },
            React.createElement(View, { flexDirection: FLEX_DIRECTION.ROW },
                React.createElement(SkeletonAurora, { width: 48, height: 32, style: style.paymentInstrumentLogo }),
                React.createElement(View, { alignItems: ALIGN.START, display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, justifyContent: ALIGN.AROUND },
                    React.createElement(SkeletonAurora, { width: 64, height: 16, marginBottom: SPACE.SPACE_4 }),
                    React.createElement(SkeletonAurora, { width: 207, height: 16 }))),
            React.createElement(SkeletonAurora, { width: 24, height: 24, style: style.accordionIcon }))));
};
