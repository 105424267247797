import { isWeb } from '../utils/platform';
export const useScroller = () => {
    const enableBackgroundScroll = () => {
        if (!isWeb)
            return;
        document.body.style.overflow = 'inherit';
    };
    const disableBackgroundScroll = () => {
        if (!isWeb)
            return;
        document.body.style.overflow = 'hidden';
    };
    return { enableBackgroundScroll, disableBackgroundScroll };
};
