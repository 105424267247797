import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    divider: {
        width: '100%',
        borderBottomStyle: '$borderStyle',
        borderBottomColor: '$colorBorderInteractive',
        borderBottomWidth: '$space025',
    },
    dividerError: {
        borderBottomColor: '$colorBorderInputError',
    },
});
