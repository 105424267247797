var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ALIGN, Button, COLOR, DISPLAY, POSITION, Skeleton, SPACE, useDevice, View } from '@lookiero/aurora';
import { useEvent } from '@lookiero/event';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useMemo, useState } from 'react';
import { getMessageForDeclineCode } from '../../domain/declineCodes';
import { PaymentInstrumentNetwork, PaymentMethod } from '../../domain/models';
import { usePaymentInstrument } from '../../hooks/usePaymentInstrument';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { emitTrackingEvent, mustGetToken } from '../../services/ioc';
import { TrackingEvents } from '../../tracking/events';
import { emitGlobalError, emitMonitoringError, emitPaymentInstrumentUpdated, internalEmitPaymentInstrumentUpdated, } from '../../utils/eventEmitter';
import { Copyright } from '../Copyright';
import { useEngine } from '../EngineProvider';
import { Label } from '../Label';
import { TEXT as TEXT_IDEAL } from '../PaymentInstrumentSelect/accordions/IdealAccordion/ideal.definition';
import { useController } from '../PaymentInstrumentSelect/PaymentInstrumentSelect.controller';
import { inAppBrowser } from '../utils/inAppBrowser/inAppBrowser';
import { updateCard } from './bridges';
import { CardElementType, TEXT } from './CardUpdater.definition';
import { getCardElementStyles, getElementBorderColor, getInputHeight, getLabelProps, style, } from './CardUpdater.styles.web';
const fieldNameMap = {
    [CardElementType.CARD_NUMBER_ELEMENT]: 'cardNumber',
    [CardElementType.CARD_CVC_ELEMENT]: 'cvc',
    [CardElementType.CARD_EXPIRY_ELEMENT]: 'expirationDate',
};
export const CardUpdaterForm = () => {
    const controller = useController((s) => s);
    const { publish } = useEvent();
    const { platform, screen } = useDevice();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elements = useElements();
    const { translate } = useLocale();
    const [complete, setComplete] = useState({});
    const [error, setError] = useState({});
    const [focus, setFocus] = useState();
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState({});
    const [touched, setTouched] = useState({});
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const stripe = useStripe();
    const paymentInstrument = usePaymentInstrument();
    const { engine } = useEngine();
    const commonOptions = useMemo(getCardElementStyles, []);
    const handleBlur = (element) => {
        setTouched((state) => (Object.assign(Object.assign({}, state), { [element]: true })));
        setFocus(undefined);
    };
    const handleChange = (event, element) => {
        const cardInfoAdded = (output) => emitTrackingEvent(TrackingEvents.CARD_INFO_ADDED, {
            output,
            part: fieldNameMap[element],
            section: controller.section,
        });
        if (event.complete) {
            cardInfoAdded('success');
        }
        else if (event.error) {
            cardInfoAdded('rejected');
        }
        setError((state) => (Object.assign(Object.assign({}, state), { [element]: event.error })));
        setComplete((state) => (Object.assign(Object.assign({}, state), { [element]: event.complete })));
    };
    const handleFocus = (focusedElement) => {
        setFocus(focusedElement);
        emitTrackingEvent(TrackingEvents.ADD_CARD_INFO, {
            part: fieldNameMap[focusedElement],
            section: controller.section,
        });
    };
    const handleReady = (element) => {
        setReady((state) => (Object.assign(Object.assign({}, state), { [element]: true })));
    };
    const emitSuccess = () => __awaiter(void 0, void 0, void 0, function* () {
        const notificationText = paymentInstrument.pendingPaymentInstrument && !paymentInstrument.info
            ? TEXT_IDEAL.NOTIFICATION_SUCCESS_IDEAL_SELECTED_AND_OTHER_TOKENIZED
            : TEXT.NOTIFICATION_SUCCESS;
        yield paymentInstrument.refresh();
        void emitPaymentInstrumentUpdated(publish, CardUpdaterForm.name, Object.assign(Object.assign({}, notificationText), { translation: translate(notificationText) }), PaymentMethod.CARD);
        setTimeout(() => internalEmitPaymentInstrumentUpdated(publish, PaymentMethod.CARD, controller.section), 0);
    });
    const emitError = (error) => {
        var _a, _b;
        const errorIsUnexpected = 
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((_a = error === null || error === void 0 ? void 0 : error.code) === null || _a === void 0 ? void 0 : _a.toString()) !== '402' && (error === null || error === void 0 ? void 0 : error.name) !== 'update_payment_instrument_error';
        emitTrackingEvent(TrackingEvents.PAYMENT_REGISTRATION, {
            paymentMethod: PaymentMethod.CARD,
            output: errorIsUnexpected ? 'error' : 'rejected',
            section: controller.section,
        });
        if (errorIsUnexpected) {
            void emitMonitoringError(publish, CardUpdaterForm.name, error);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const messageDefinition = getMessageForDeclineCode((_b = error === null || error === void 0 ? void 0 : error.payload) === null || _b === void 0 ? void 0 : _b.code);
        void emitGlobalError(publish, CardUpdaterForm.name, {
            toaster: messageDefinition,
            id: messageDefinition === null || messageDefinition === void 0 ? void 0 : messageDefinition.id,
            translation: messageDefinition ? translate(messageDefinition) : '',
        });
    };
    const handleUpdatePress = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const cardNumberElement = elements.getElement(CardNumberElement);
            yield updateCard({ platform: 'web', token: yield mustGetToken(), cardNumberElement, stripe }, engine);
            yield emitSuccess();
            if (paymentInstrument.pendingPaymentInstrument && paymentInstrument.info) {
                yield paymentInstrument.updatePendingPaymentInstrument(null);
            }
            setLoading(false);
            setTouched({});
            controller.hideAllPanels();
            emitTrackingEvent(TrackingEvents.PAYMENT_REGISTRATION, {
                paymentMethod: PaymentMethod.CARD,
                output: 'success',
                section: controller.section,
            });
        }
        catch (error) {
            emitError(error);
            setLoading(false);
        }
    });
    const areElementsReady = () => Object.values(CardElementType).every((element) => ready[element]);
    const isFormComplete = () => Object.values(CardElementType).every((element) => complete[element]);
    const isDesktop = platform.web && (screen.M || screen.L);
    const focusedElement = (element) => focus === element;
    const touchedElement = (element) => Object.keys(touched).some((key) => key === element);
    const shouldShowError = (element) => !!(error && error[element]) || (!complete[element] && touchedElement(element) && !focusedElement(element));
    return (React.createElement(View, { justifyContent: isDesktop ? ALIGN.START : ALIGN.BETWEEN },
        React.createElement(SkeletonWrapper, { visible: !areElementsReady() }),
        React.createElement(View, { position: POSITION.RELATIVE, display: areElementsReady() ? undefined : DISPLAY.NONE },
            React.createElement(Label, Object.assign({}, getLabelProps(focusedElement(CardElementType.CARD_NUMBER_ELEMENT), touchedElement(CardElementType.CARD_NUMBER_ELEMENT), shouldShowError(CardElementType.CARD_NUMBER_ELEMENT))), translate(TEXT.INPUT_NUMBER)),
            React.createElement(View, { backgroundColor: COLOR.BASE, borderColor: getElementBorderColor(focusedElement(CardElementType.CARD_NUMBER_ELEMENT), shouldShowError(CardElementType.CARD_NUMBER_ELEMENT)), style: style.border, marginBottom: SPACE.SPACE_4, paddingHorizontal: SPACE.SPACE_4, position: POSITION.RELATIVE, testID: 'CardNumberElementWrapper' },
                React.createElement(CardNumberElement, { onChange: (event) => handleChange(event, CardElementType.CARD_NUMBER_ELEMENT), onBlur: () => handleBlur(CardElementType.CARD_NUMBER_ELEMENT), onFocus: () => handleFocus(CardElementType.CARD_NUMBER_ELEMENT), onReady: () => handleReady(CardElementType.CARD_NUMBER_ELEMENT), options: Object.assign(Object.assign({}, commonOptions), { showIcon: true, preferredNetwork: [PaymentInstrumentNetwork.CARTES_BANCAIRES] }) }))),
        React.createElement(SkeletonWrapper, { visible: !areElementsReady() }),
        React.createElement(View, { position: POSITION.RELATIVE, display: areElementsReady() ? undefined : DISPLAY.NONE },
            React.createElement(Label, Object.assign({}, getLabelProps(focusedElement(CardElementType.CARD_EXPIRY_ELEMENT), touchedElement(CardElementType.CARD_EXPIRY_ELEMENT), shouldShowError(CardElementType.CARD_EXPIRY_ELEMENT))), translate(TEXT.INPUT_EXPIRATION_DATE)),
            React.createElement(View, { backgroundColor: COLOR.BASE, borderColor: getElementBorderColor(focusedElement(CardElementType.CARD_EXPIRY_ELEMENT), shouldShowError(CardElementType.CARD_EXPIRY_ELEMENT)), style: style.border, paddingHorizontal: SPACE.SPACE_4, position: POSITION.RELATIVE, marginBottom: SPACE.SPACE_4, testID: 'CardExpiryElementWrapper' },
                React.createElement(CardExpiryElement, { onBlur: () => handleBlur(CardElementType.CARD_EXPIRY_ELEMENT), onChange: (event) => handleChange(event, CardElementType.CARD_EXPIRY_ELEMENT), onFocus: () => handleFocus(CardElementType.CARD_EXPIRY_ELEMENT), onReady: () => handleReady(CardElementType.CARD_EXPIRY_ELEMENT), options: commonOptions }))),
        React.createElement(SkeletonWrapper, { visible: !areElementsReady() }),
        React.createElement(View, { position: POSITION.RELATIVE, display: areElementsReady() ? undefined : DISPLAY.NONE },
            React.createElement(Label, Object.assign({}, getLabelProps(focusedElement(CardElementType.CARD_CVC_ELEMENT), touchedElement(CardElementType.CARD_CVC_ELEMENT), shouldShowError(CardElementType.CARD_CVC_ELEMENT))), translate(TEXT.INPUT_CVC)),
            React.createElement(View, { backgroundColor: COLOR.BASE, borderColor: getElementBorderColor(focusedElement(CardElementType.CARD_CVC_ELEMENT), shouldShowError(CardElementType.CARD_CVC_ELEMENT)), style: style.border, paddingHorizontal: SPACE.SPACE_4, position: POSITION.RELATIVE, marginBottom: SPACE.SPACE_6, testID: 'CardCvcElementWrapper' },
                React.createElement(CardCvcElement, { onBlur: () => handleBlur(CardElementType.CARD_CVC_ELEMENT), onChange: (event) => handleChange(event, CardElementType.CARD_CVC_ELEMENT), onFocus: () => handleFocus(CardElementType.CARD_CVC_ELEMENT), onReady: () => handleReady(CardElementType.CARD_CVC_ELEMENT), options: commonOptions }))),
        React.createElement(Button, { busy: loading, disabled: !isFormComplete(), testID: 'updateCardButton', onPress: handleUpdatePress }, translate(TEXT.CTA_UPDATE)),
        inAppBrowser ? React.createElement(Copyright, null) : React.createElement(React.Fragment, null)));
};
const SkeletonWrapper = ({ visible }) => visible ? (React.createElement(View, { marginBottom: SPACE.SPACE_6 },
    React.createElement(Skeleton, { height: getInputHeight() }))) : (React.createElement(React.Fragment, null));
