var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEvent } from '@lookiero/event';
import { useEffect, useState } from 'react';
import { mustGetToken } from '../../../../services/ioc';
import { emitGlobalErrorAndMonitor } from '../../../../utils/eventEmitter';
import { availableIntegrationsService } from './AvailableIntegrations.service';
export function useAvailableIntegrations(section) {
    const { publish } = useEvent();
    const [availableIntegrations, setAvailableIntegrations] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            setLoading(true);
            const result = yield availableIntegrationsService.get(section, yield mustGetToken(), (err) => emitGlobalErrorAndMonitor(publish, useAvailableIntegrations.name, err));
            setAvailableIntegrations((d) => (Object.assign(Object.assign({}, d), { [section]: result })));
            setLoading(false);
        }))();
    }, [publish, section]);
    return { integrations: availableIntegrations[section] || [], loading };
}
