import { Text, View, COLOR, SPACE } from '@lookiero/aurora';
import React from 'react';
import { PaymentMethod } from '../../../../domain/models';
import { useLocale } from '../../../../infrastructure/hooks/useLocale';
import { findAccordionMap } from '../../accordions';
import { useController } from '../../PaymentInstrumentSelect.controller';
import { TEXT } from '../../PaymentInstrumentSelect.definition';
import { usePaymentMethodsAvailability } from '../AvailableIntegrations/usePaymentMethodsAvailability';
const PayPalDisclaimer = () => {
    var _a;
    const controller = useController((s) => s);
    const availability = usePaymentMethodsAvailability();
    const { translate } = useLocale();
    const { engine } = availability[PaymentMethod.PAYPAL];
    const showDisclaimer = engine != null && Boolean(findAccordionMap(PaymentMethod.PAYPAL, engine));
    if (!showDisclaimer)
        return null;
    const customComponent = (_a = controller.disclaimers) === null || _a === void 0 ? void 0 : _a[PaymentMethod.PAYPAL];
    if (customComponent)
        return React.createElement(customComponent);
    return (React.createElement(View, { marginTop: SPACE.SPACE_4 },
        React.createElement(Text, { detail: true, level: 2, color: COLOR.TEXT_MEDIUM }, translate(TEXT.PAYMENT_METHOD_PAYPAL_DISCLAIMER))));
};
export { PayPalDisclaimer };
