import gql from 'graphql-tag';
export const CHECKOUT_ORDER = gql `
  mutation performCheckout($checkout: Checkout!) {
    performCheckout(checkout: $checkout) {
      chargeExecution {
        chargeId
        status
        metadata
        declineCode
      }
      errors {
        code
      }
      result
    }
  }
`;
