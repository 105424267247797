import StyleSheet from 'react-native-extended-stylesheet';
export const style = StyleSheet.create({
    touchable: {
        width: '100%',
    },
    container: {
        borderStyle: '$borderStyle',
        borderColor: '$colorBorderInteractive',
        borderWidth: '$space025',
        borderRadius: '$borderRadius3',
    },
    containerError: {
        borderColor: '$colorBorderInputError',
    },
});
