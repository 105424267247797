var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { pendingActionsRepository, PendingPaymentType } from '../../infrastructure/repositories';
export var PendingPaymentErrorCode;
(function (PendingPaymentErrorCode) {
    PendingPaymentErrorCode["PENDING_PAYMENT_PAY_1"] = "pending_payment_pay1";
    PendingPaymentErrorCode["PENDING_PAYMENT_PAY_2"] = "pending_payment_pay2";
})(PendingPaymentErrorCode || (PendingPaymentErrorCode = {}));
export const getPendingPayment = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const pendingActionsResponse = yield pendingActionsRepository.fetchPendingActions();
    const service = (_a = pendingActionsResponse.PAYMENT[0]) === null || _a === void 0 ? void 0 : _a.services[0];
    if (!service || !service.boxId || !service.pendingPayments[0]) {
        return null;
    }
    const { id, date = new Date(), type = PendingPaymentType.SERVICE, amountToPay = {
        total: 0,
        breakdown: {
            paymentInstrument: 0,
            balance: 0,
        },
    }, } = service.pendingPayments[0];
    const errorCode = type === PendingPaymentType.SERVICE
        ? PendingPaymentErrorCode.PENDING_PAYMENT_PAY_1
        : PendingPaymentErrorCode.PENDING_PAYMENT_PAY_2;
    return {
        amount: amountToPay.total,
        amountToPay,
        error: {
            code: errorCode,
        },
        date: new Date(date),
        id: id,
        order: `LK-${service.boxId}`,
        type: '',
    };
});
