var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChargeStatus } from '../../components';
import { getMessageForDeclineCode } from '../../domain/declineCodes';
import { chargeRepository } from '../../infrastructure/repositories';
import { store } from '../PaymentStatusManager';
export const refreshCharge = (args) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { section, engine, paymentMethod, chargeId, paymentResult = '' } = args;
    const state = store.getState();
    try {
        state.init(section, { paymentMethod, chargeId });
        if (paymentResult !== 'succeeded') {
            throw new Error();
        }
        const chargeRefresh = yield chargeRepository.refreshCharge(engine, paymentMethod, chargeId);
        const isSucceededCharge = chargeRefresh.status === ChargeStatus.EXECUTED;
        state.finalize(section, { success: isSucceededCharge });
    }
    catch (error) {
        const errorCode = ((_a = error === null || error === void 0 ? void 0 : error.payload) === null || _a === void 0 ? void 0 : _a.code) || '';
        const messageDefinition = getMessageForDeclineCode(errorCode);
        state.finalize(section, {
            success: false,
            metadata: {
                id: messageDefinition.id,
                toaster: messageDefinition,
            },
        });
    }
});
