var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Motion, POINTER, POSITION, SIZE, Text, Theme, useBanProps, useSupportedProps } from '@lookiero/aurora';
import React from 'react';
const CONTAINER_PROPS = ['style', 'layer', 'position'];
const Label = (_a) => {
    var { children } = _a, props = __rest(_a, ["children"]);
    const theme = Theme.get();
    return (React.createElement(Motion, Object.assign({}, useSupportedProps(props, CONTAINER_PROPS), { pointerEvents: POINTER.NONE, position: POSITION.ABSOLUTE, layer: SIZE.L, testID: 'label', value: {
            top: props.level === 3 ? theme.space4 + theme.space05 : Number(theme.space2) * -1,
            left: props.level === 3 ? theme.space4 : theme.space3,
        } }),
        React.createElement(Text, Object.assign({}, useBanProps(props, CONTAINER_PROPS)), children)));
};
export { Label };
