var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '@lookiero/data-sources';
import { getHeaders } from '../headers';
export class CheckoutRepository {
    constructor(token) {
        this.token = token;
    }
    reconcile(checkoutUuid, withBalance, paymentInstrument) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/checkout/v1/checkout/reconcile',
                method: 'POST',
                headers: getHeaders(this.token),
                uuid: checkoutUuid,
                useBalance: withBalance,
                engine: paymentInstrument.engine,
                paymentMethod: paymentInstrument.payment_method,
            });
        });
    }
    confirmCharge({ chargeId, engine, paymentMethod }) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/checkout/v1/confirm',
                method: 'POST',
                headers: getHeaders(this.token),
                chargeId,
                engine,
                paymentMethod,
            });
        });
    }
    cancelCharge(chargeId, reason) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/checkout/v1/cancel',
                method: 'POST',
                headers: getHeaders(this.token),
                chargeId,
                reason,
            });
        });
    }
}
