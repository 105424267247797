var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { generateIdealPaymentConfirmError, parsePaymentStatus } from './StripeService.controller';
export class StripeService {
    constructor(instance) {
        this.instance = instance;
    }
    getStripeEngine() {
        return this.instance;
    }
    static handleURLCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            return Promise.resolve(false);
        });
    }
    confirmIdealPayment(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const { clientSecret, data } = args;
            const response = yield this.instance
                .confirmIdealPayment(clientSecret, {
                payment_method: {
                    ideal: {},
                    billing_details: {
                        name: data.billingDetails.name,
                        email: data.billingDetails.email,
                    },
                },
                return_url: data.returnUrl,
            })
                .catch((error) => {
                return { paymentIntent: undefined, error };
            });
            if (response.paymentIntent) {
                return {
                    paymentIntent: {
                        status: parsePaymentStatus(response.paymentIntent.status),
                    },
                };
            }
            return { error: generateIdealPaymentConfirmError(response.error) };
        });
    }
}
