var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AsyncStorage, LocalAdapter } from '@lookiero/data-sources';
import { mustGetToken } from '../services/ioc';
import { parseUserToken } from '../utils/parseUserToken';
export class CacheStorageService {
    constructor(storage) {
        this.storage = storage;
    }
    buildCacheKey(key) {
        return __awaiter(this, void 0, void 0, function* () {
            return `${key}-${parseUserToken(yield mustGetToken()).uuid}`;
        });
    }
    cacheIsExpired(cache, ttl) {
        return new Date().getTime() - new Date(cache.savedDate).getTime() > ttl;
    }
    get(key, ttl) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = yield this.buildCacheKey(key);
            if (!(yield (yield this.storage).has(cacheKey)))
                return null;
            const data = yield (yield this.storage).get(cacheKey);
            if (ttl && this.cacheIsExpired(data, ttl))
                return null;
            return data;
        });
    }
    remove(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = yield this.buildCacheKey(key);
            yield (yield this.storage).remove(cacheKey);
        });
    }
    set(key, value) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = yield this.buildCacheKey(key);
            const cacheValue = {
                savedDate: new Date().getTime(),
                value,
            };
            yield (yield this.storage).set(cacheKey, cacheValue);
        });
    }
}
export const cacheStorageService = new CacheStorageService(new AsyncStorage({ adapter: LocalAdapter }));
