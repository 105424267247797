import { ALIGN, Box, COLOR, Layout, SPACE, Text } from '@lookiero/aurora';
import React from 'react';
import { useLocale } from '../../infrastructure/hooks/useLocale';
import { EngineProvider, useEngine } from '../EngineProvider';
import { TEXT } from './CardUpdater.definition';
import { CardUpdaterForm } from './CardUpdater.form';
const CardUpdater = () => {
    const { translate, locale } = useLocale();
    const { engine, engineInstance } = useEngine();
    if (!locale)
        return React.createElement(React.Fragment, null);
    return (React.createElement(Layout, { alignItems: ALIGN.START, justifyContent: ALIGN.CENTER, style: { flex: 1 }, testID: "Layout" },
        React.createElement(Box, { size: { M: '2/3', L: '1/3' }, style: {
                flex: 1,
                flexDirection: 'column',
            } },
            React.createElement(Text, { as: "p", color: COLOR.TEXT, heading: true, level: 3, marginBottom: SPACE.SPACE_6 }, translate(TEXT.UPDATE_TITLE_CARD)),
            React.createElement(EngineProvider, { engine: engine, engineInstance: engineInstance, locale: locale },
                React.createElement(CardUpdaterForm, null)))));
};
export { CardUpdater };
