var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChallengeResult } from './PaymentFlowStrategy.definition';
import { callback } from './utils/callback';
export class PaymentFlowUI {
    constructor(set) {
        this.set = set;
    }
    displayChallenge(url, timeoutInSeconds) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.challengeLoadingWhile(this.loadChallengeUrl(url));
            this.set('challengeVisible', true);
            const closeCallback = this.set('onChallengeClose', callback());
            const timeout = setTimeout(() => {
                closeCallback.call(ChallengeResult.TIMED_OUT);
            }, timeoutInSeconds * 1000);
            const result = yield closeCallback.promise;
            clearTimeout(timeout);
            this.set('challengeUrl', '');
            this.set('challengeVisible', false);
            return result;
        });
    }
    loadChallengeUrl(url) {
        return __awaiter(this, void 0, void 0, function* () {
            const challengeLoaded = this.set('onChallengeLoad', callback()).promise;
            this.set('challengeUrl', url);
            yield challengeLoaded;
        });
    }
    loadingWhile(promise) {
        return __awaiter(this, void 0, void 0, function* () {
            this.set('loading', true);
            const result = yield promise;
            this.set('loading', false);
            return result;
        });
    }
    challengeLoadingWhile(promise) {
        return __awaiter(this, void 0, void 0, function* () {
            this.set('challengeLoading', true);
            const result = yield promise;
            this.set('challengeLoading', false);
            return result;
        });
    }
}
