import { DISPLAY, FLEX_DIRECTION, SIZE, SPACE, Text, View } from '@lookiero/aurora';
import React from 'react';
import { PaymentInstrumentBrand } from '../../../../domain/models';
import { useLocale } from '../../../../infrastructure/hooks/useLocale';
import { PaymentAccordion } from '../../../PaymentAccordion';
import { TEXT } from '../../PaymentInstrumentSelect.definition';
export const CardAccordion = (props) => {
    var _a, _b, _c;
    const { translate } = useLocale();
    const brand = ((_a = props.paymentInstrument) === null || _a === void 0 ? void 0 : _a.metadata.brand) || PaymentInstrumentBrand.CARD;
    const networks = ((_b = props.paymentInstrument) === null || _b === void 0 ? void 0 : _b.metadata.availableNetworks) || [];
    const preferredNetwork = (_c = props.paymentInstrument) === null || _c === void 0 ? void 0 : _c.metadata.preferredNetwork;
    return (React.createElement(PaymentAccordion, { metadata: { brand, networks, preferredNetwork }, testID: 'CardAccordion' },
        React.createElement(View, { display: DISPLAY.FLEX, flexDirection: FLEX_DIRECTION.COLUMN, marginRight: SPACE.SPACE_4, flex: SIZE.XS },
            React.createElement(Text, { action: true, ellipsizeMode: true, level: 3 }, props.paymentInstrument != null
                ? `${translate(TEXT.CARD)} *${props.paymentInstrument.metadata.last4}`
                : translate(TEXT.CREDIT_CARD)))));
};
