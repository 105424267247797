var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { PaymentMethod } from '../../domain/models';
import { paymentInstrumentRepository } from '../../infrastructure/repositories';
const changePaymentInstrument = (engine, paymentMethod, token) => __awaiter(void 0, void 0, void 0, function* () {
    const { errors: [error] = [] } = yield paymentInstrumentRepository.changePaymentInstrumentByPaymentMethodId(engine, PaymentMethod.CARD, paymentMethod.id, token);
    if (error) {
        throw error;
    }
    return paymentMethod;
});
const createPaymentMethod = ({ element, stripe }) => __awaiter(void 0, void 0, void 0, function* () {
    return (yield stripe.createPaymentMethod({
        type: 'card',
        card: element,
    })).paymentMethod;
});
const paymentInstrumentError = () => {
    return new Error('Payment Instrument Error', {
        cause: {
            name: 'update_payment_instrument_error',
            message: 'We had a problem creating the payment instrument, please try again later.',
        },
    });
};
const updateCardNative = (createPaymentMethod, engine, token) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield createPaymentMethod({ paymentMethodType: 'Card' });
    if (!response) {
        throw paymentInstrumentError();
    }
    if (response.error) {
        throw response.error;
    }
    return yield changePaymentInstrument(engine, response.paymentMethod, token);
});
const updateCardWeb = (element, engine, stripe, token) => __awaiter(void 0, void 0, void 0, function* () {
    const paymentMethod = yield createPaymentMethod({ element, stripe });
    if (!paymentMethod) {
        throw paymentInstrumentError();
    }
    return yield changePaymentInstrument(engine, paymentMethod, token);
});
export { updateCardNative, updateCardWeb };
