import { View } from '@lookiero/aurora';
import React, { useRef, useEffect } from 'react';
import { Animated, Easing } from 'react-native';
import { styles } from './Spinner.styles';
import SpinnerImage from './svg/SpinnerImage';
export const Spinner = ({ isVisible = false, marginBottom }) => {
    const rotateAnim = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        if (!isVisible)
            return;
        const loop = Animated.loop(Animated.timing(rotateAnim, {
            toValue: 1,
            duration: 1000,
            useNativeDriver: false,
            easing: Easing.linear,
        }));
        loop.start();
        return () => loop.stop();
    }, [rotateAnim, isVisible]);
    const spin = rotateAnim.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '360deg'],
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(View, { marginBottom: marginBottom, style: [styles.container, { display: isVisible ? undefined : 'none' }], testID: 'spinner' },
            React.createElement(Animated.View, { style: { transform: [{ rotate: spin }] } },
                React.createElement(SpinnerImage, null)))));
};
