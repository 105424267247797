var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '@lookiero/data-sources';
import { mustGetToken } from '../../services/ioc';
import { getHeaders } from '../headers';
class ChargeRepositoryImpl {
    chargePsService(engine, paymentMethod, boxId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: `/payments/charge/psservice`,
                method: 'POST',
                headers: getHeaders(yield mustGetToken()),
                engine,
                paymentMethod,
                boxId,
            });
        });
    }
    refreshCharge(engine, paymentMethod, chargeId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: `/web/api/payments/v1/charge/${chargeId}/refresh`,
                method: 'POST',
                headers: getHeaders(yield mustGetToken()),
                engine,
                paymentMethod,
            });
        });
    }
    confirmCharge(chargeId) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield request({
                endpoint: '/web/api/payments/v1/charge/confirm',
                method: 'POST',
                headers: getHeaders(yield mustGetToken()),
                chargeId,
            });
        });
    }
}
export const chargeRepository = new ChargeRepositoryImpl();
