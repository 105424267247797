import { Countries, Currencies, Locales } from '../domain/models';
export const getCountryByLocale = (locale) => {
    const country = locale.split('-')[1];
    return Countries[country];
};
export const getCurrencyByLocale = (locale) => {
    if (!locale)
        return Currencies.EUR;
    if (locale === Locales.EN_GB)
        return Currencies.GBP;
    if (locale === Locales.SV_SE)
        return Currencies.SEK;
    if ([Locales.DE_CH, Locales.FR_CH, Locales.IT_CH].includes(locale))
        return Currencies.CHF;
    return Currencies.EUR;
};
